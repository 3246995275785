import {
  Button,
} from "@material-ui/core";
import React, { memo } from "react";
import DefaultDialog from "./context/Dialog";

const ConfirmModal = memo((props) => {
  return (
    <DefaultDialog
      title={props.title || "Confirm"}
      maxWidth="sm"
      onClose={() => props.onClose()}
      actionProps={{
        style: { justifyContent: "center" },
      }}
      actions={
        <>
          <Button
            size="small"
            variant="contained"
            onClick={() => props.onClose()}
            className="btn-blue"
          >
            OKAY
          </Button>
        </>
      }
    >
      <>
        <div style={{ padding: "12px 0" }}>
          {typeof props?.description === "string" ? (
            <p>{props?.description || ""}</p>
          ) : (
            props?.description
          )}
        </div>
      </>
    </DefaultDialog>
  );
});

export default ConfirmModal;
