import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import { connect } from "react-redux";
import { io } from "socket.io-client";

const SocketURL =
  process.env.REACT_APP_WS_CONNECTION_URL || "wss://salonpartners.bellebiz.com";

const defaultProps = {
  socket: null,
};

export const SocketIOContext = createContext(defaultProps);

const SocketIOProvider = (props) => {
  const { center, children } = props;
  const [socket, setSocket] = useState(null);
  // const { token } = useAuth();

  const join = useCallback(
    (spaCenterId) => {
      if (socket) {
        socket.disconnect();
        socket.io.opts.query = {
          ...(socket.io.engine.query || {}),
          spaCenterId,
        };
        socket.connect();
      }
    },
    [socket]
  );

  const onConnect = useCallback(() => {
    return socket?.emit("join", (data) => {});
  }, [socket]);

  const onDisconnect = useCallback(() => {
    console.log("onDisconnect");
  }, []);

  useEffect(() => {
    if (socket && center?.id) {
      join(center?.id);
    }
  }, [socket, center?.id]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("onJoin", (data) => console.log("join", data));
    }
  }, [socket, onConnect, onDisconnect]);

  useEffect(() => {
    const socket = io(SocketURL, {
      // transports: ["polling", "websocket"],
      // secure: true,
      //path: '/socket.io'
    });
    setSocket(socket);
  }, [setSocket]);

  const onEmitUpdateBooking = useCallback(
    (bookingId) => {
      return new Promise((resolve, reject) => {
        socket.emit("onUpdateBooking", { bookingId }, (data, error) => {
          if (data.status == "error") {
            reject(error);
            return;
          }
          resolve(data);
        });
      });
    },
    [socket]
  );

  const onEmitCreateBooking = useCallback(
    (bookingId) => {
      return new Promise((resolve, reject) => {
        socket.emit("onCrateBooking", { bookingId }, (data, error) => {
          if (data.status == "error") {
            reject(error);
            return;
          }
          resolve(data);
        });
      });
    },
    [socket]
  );
  const onEmitNotificationCountUpdate = useCallback(
    (spaCenterId) => {
      return new Promise((resolve, reject) => {
        socket.emit("onNotificationCount", { spaCenterId }, (data, error) => {
          if (data.status == "error") {
            reject(error);
            return;
          }
          resolve(data);
        });
      });
    },
    [socket]
  );

  return (
    <SocketIOContext.Provider
      value={{
        socket,
        join,
        onEmitUpdateBooking,
        onEmitCreateBooking,
        onEmitNotificationCountUpdate,
      }}
    >
      {children}
    </SocketIOContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => ({
  center: state.center || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(SocketIOProvider);

export const useSocket = () => useContext(SocketIOContext);
