import {
  Button,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { memo, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateCustomer } from "../actions";
import api from "../api";
import DefaultDialog from "./context/Dialog";

const editCustomer = (data, id) => {
  return api.post("/customer/:id", data, { params: { id } });
};

const ChangeCustomerNameModal = memo((props) => {
  const [name, setName] = useState("");
  const [error, setError] = useState(null);

  const handleChange = useCallback(
    (e) => {
      setName(e.target.value);
      if (error) {
        setError(null);
      }
    },
    [error]
  );

  const handleSave = useCallback(() => {
    const d = new Date();
    const data = {
      utc_offset: d.getTimezoneOffset() * -1,
      name,
    };
    editCustomer(data, props.cart.customer_id)
      .then((res) => {
        props.dispatch(
          updateCustomer({
            customer_id: props.cart.customer_id,
            customer_name: name,
          })
        );
        props.onClose();
      })
      .catch((err) => {
        setError("Please Enter your name");
        console.log(err);
      });
    //props.dispatch(updateBookingNote({ data, booking_id: props.booking.booking_id }));
  }, [name, props.onClose, props.dispatch]);

  useEffect(() => {
    setName(props.cart.customer_name);
  }, [props.cart.customer_name]);

  return (
    <DefaultDialog
      title="Change Name"
      maxWidth="sm"
      onClose={() => props.onClose()}
      actionProps={{
        style: { justifyContent: "center" },
      }}
      actions={
        <Button
          variant="contained"
          onClick={handleSave}
          className="btn-blue"
        >
          SAVE
        </Button>
      }
    >
      <div style={{ padding: "16px 0" }}>
        {error && (
          <Alert
            className="mb-3"
            onClose={() => setError(null)}
            severity="error"
          >
            {error}
          </Alert>
        )}
        <TextField
          variant="outlined"
          autoFocus
          placeholder="Enter Name"
          className="text-area-note"
          value={name || ""}
          onChange={handleChange}
        />
      </div>
    </DefaultDialog>
  );
});

const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapDispatchToProps)(ChangeCustomerNameModal);
