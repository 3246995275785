import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";

const DefaultDialog = ({
  title,
  children,
  actions,
  hideCloseButton,
  fullHight = true,
  onClose,
  dialogContentProps,
  actionProps,
  ...dialogProps
}) => {
  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      className="default-dialog"
      {...dialogProps}
    >
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="h6">{title}</Typography>
        <IconButton
          className="close-btn"
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          {" "}
          <CloseIcon fontSize="large" />{" "}
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ padding: "20px", fontSize: "16px" }}
        {...dialogContentProps}
      >
        {children}
      </DialogContent>
      {actions && <DialogActions {...actionProps}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default DefaultDialog;
