import { Button } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import DefaultDialog from "./context/Dialog";
import { ordinalSuffixOf } from "../helpers/helper";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const BookingConfirmModal = memo((props) => {
  const isDoubleBooking = useMemo(() => {
    return !!props.center.setting.double_booking;
  }, [props?.center?.setting?.double_booking]);

  const dateTime = useMemo(() => {
    const dt = new Date(props?.date);
    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return `${dt.getDate()} ${
      months[dt.getMonth()]
    } ${dt.getFullYear()}, ${strTime}`;
  }, [props?.date]);

  const description = useMemo(() => {
    if (props?.groupBookingCount > 1) {
      return `You have successfully added ${ordinalSuffixOf(
        props?.groupBookingCount
      )} person into your appointment on ${dateTime}. Would you like to add more person ?`;
    } else if (isDoubleBooking) {
      return `Your appointment has been added in waiting list for ${dateTime}. We will check and confirm your appointment shortly. Would you like to add another person ?`;
    } else {
      return `Your appointment has been reserved for ${dateTime}. Would you like to
              add another person into your appointment?`;
    }
  }, [isDoubleBooking, dateTime, props?.groupBookingCount]);

  return (
    <DefaultDialog
      maxWidth="sm"
      title={isDoubleBooking ? "Appointment Pending" : "Appointment Confirmed"}
      onClose={() => props.onClose()}
      actionProps={{
        style: { justifyContent: "center" },
      }}
      actions={
        <>
          <Button
            size="small"
            onClick={() => props.onClose()}
            color="primary"
            className="small-btn"
          >
            No,I'm done
          </Button>
          <Button
            size="small"
            onClick={(e) => props.onAddOtherPerson(e)}
            color="secondary"
            className="small-btn"
          >
            Yes,Add person
          </Button>
        </>
      }
    >
      <div style={{ padding: "24px 0", fontSize: "16px" }}>
        <p>{description}</p>
      </div>
    </DefaultDialog>
  );
});

export default BookingConfirmModal;
