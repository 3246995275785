import requestService from '../services/requestService';

const getEmployees = query => {
    const request = {
      method: "get",
      url: `/center/${query.cid}/employee`,
      params: {
        service: query.service.join(","),
        type: "get_employees",
        date: query.date,
        time: query.time,
      },
    };

    return requestService(request);
};

const getEmployeeAvailability = query => {
    const request = {
        method: 'get',
        url: `/employee/${query.eid}/availability`,
        params: {
            date: query.date,
            sid: query.sid.join(','),
            cid: query.cid,
            type: 'get_employee_availability',
        }
    };
    if (query.booking_id) {
        request.params.booking_id = query.booking_id;
    }
    return requestService(request);
};

export default {
    getEmployees,
    getEmployeeAvailability
};
