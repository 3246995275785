import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import api from '../api';

const getBusinessHour = cid => {
    return api.get('/center/:cid/business-hour', { params: { cid } });
}

const sortBusinessHour = bh => {
    const sortOrder = [2, 3, 4, 5, 6, 7, 1];
    const newBh = [];
    sortOrder.forEach(i => {
        const data = bh.find((d) => d.day_number === i);
        if(data){
        newBh.push(data);
        }
    });
    return newBh;
};

const About = React.memo(props => {
    const [businessHour, setBusinessHour] = useState([]);

    useEffect(() => {
        if (props.id) {
            getBusinessHour(props.id).then(res => {
                const { data = [] } = res;
                setBusinessHour(sortBusinessHour(data));
            }).catch(err => {
                setBusinessHour([]);
            });
        }
    }, [props.id]);

    return (
      <section className="home-about">
        <div className="container">
          <h4>Let’s Get in Touch</h4>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={4}>
              <div className="map">
                <iframe
                  title="map"
                  src={`https://www.google.com/maps?output=embed&q=${props.address}`}
                  allowFullScreen=""
                ></iframe>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <h5>
                <i className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#14B9DB"
                  >
                    <path d="M11.9998 0C5.37257 0 0 5.37273 0 11.9998C0 18.6269 5.37257 24 11.9998 24C18.627 24 24 18.6269 24 11.9998C24 5.37273 18.627 0 11.9998 0ZM17.1876 14.2822H12.1002C12.083 14.2822 12.067 14.278 12.05 14.2774C12.0329 14.2782 12.017 14.2822 11.9997 14.2822C11.5414 14.2822 11.1698 13.9106 11.1698 13.4522V4.98C11.1698 4.52168 11.5414 4.15007 11.9997 4.15007C12.458 4.15007 12.8297 4.52168 12.8297 4.98V12.6223H17.1873C17.6457 12.6223 18.0173 12.9939 18.0173 13.4522C18.0173 13.9106 17.646 14.2822 17.1876 14.2822Z" />
                  </svg>
                </i>
                Business hours:
              </h5>
              <table>
                <tbody>
                  {businessHour.map((i) => (
                    <tr key={i.day_number}>
                      <th>{i.day_name} </th>
                      <td>
                        {i.is_on
                          ? `${i.start_hour}:${("0" + i.start_minute).slice(
                              -2
                            )} ${i.start_period.toUpperCase()} - ${
                              i.end_hour
                            }:${("0" + i.end_minute).slice(
                              -2
                            )} ${i.end_period.toUpperCase()}`
                          : "Closed"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={6} xl={5}>
              <div className="data">
                <h5>About us</h5>
                <p>{props.about_us}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    );
});

export default About;