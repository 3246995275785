import { Button, Typography } from "@material-ui/core";
import React, { useCallback, createContext } from "react";
import DefaultDialog from "./Dialog";

export const ConfirmContext = createContext(null);

export const defaultProps = {
  title: "Confirm",
  description: "",
  yesText: "Yes",
  noText: "No",
  dialogProps: {},
  yesButtonProps: {},
  noButtonProps: {},
  content: "",
};

const ConfirmDialog = ({
  noButtonProps,
  noText,
  yesButtonProps,
  yesText,
  description,
  resolveReject,
  content,
  onClose,
  ...dialogProps
}) => {
  const [resolve, reject] = resolveReject;

  const handleCancel = useCallback(() => {
    reject();
    onClose && onClose();
  }, [reject, onClose]);

  const handleConfirm = useCallback(() => {
    resolve();
    onClose && onClose();
  }, [resolve, onClose]);

  return (
    <DefaultDialog
      title={dialogProps?.title}
      maxWidth="sm"
      onClose={handleCancel}
      actions={
        <>
          <Button
            variant="contained"
            color="inherit"
            {...noButtonProps}
            onClick={handleCancel}
          >
            {noText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            {...yesButtonProps}
            onClick={handleConfirm}
          >
            {yesText}
          </Button>
        </>
      }
    >
      <>
        {description && <Typography>{description}</Typography>}
        {content}
      </>
    </DefaultDialog>

  );
};

export default ConfirmDialog;
