import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
//import "./CheckoutForm.css";
import api from "../api";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@material-ui/core";

export default function StripeCheckoutForm(props) {
  // const [amount, setAmount] = useState(0);
  // const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  // const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [isAgree, setIsAgree] = useState(true)

  useEffect(() => {
    api
      .post("/payment/stripe", {
        payment_method_types: ["card"],
        amount: props.amount,
        centerId: props.center.id,
        // capture_method: "manual", // This indicates a hold
        // confirm: true,
        // expand: ["latest_charge"],
        // payment_method_options: {
        //   card: {
        //     request_extended_authorization: "if_available", // Extend auto cancel limit upto 30 days https://docs.stripe.com/payments/extended-authorization
        //   },
        // },
      })
      .then((result) => {
        console.log("Returned client secret:");
        console.log(result.data);
        setClientSecret(result.data.client_secret);
      })
      .catch((err) => {
        console.log("Error in creating stripe payment intent:" + err);
        setError(err.message);
      });
  }, []);



  
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    //return;
    setProcessing(true)
    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    // const payload = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //   },
    // });

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: ev?.target?.name?.value,
          address: {
            postal_code: ev?.target?.post_code?.value,
          },
        },
      },
    });

   
    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
      console.log("[error]", payload.error);
    } else {
      setError(null);
      setMetadata(payload.paymentIntent);
      console.log("Payment success:", payload.paymentIntent);
      props.onApprove(payload.paymentIntent);
    }
  };

  const renderForm = () => {
    const options = {
      showIcon: true,
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color:"#a2a2a2",
            fontWeight: 300,
            fontSize: "16px",
            letterSpacing: 0.9,
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name on Card"
              autoComplete="cardholder"
              className="sr-input sr-name-input"
              required
            />
          </div>
          <div className="sr-combo-inputs-row">
            <CardNumberElement
              className="sr-input sr-card-element"
              options={{ ...options, placeholder: "Card Number" }}
            />
          </div>
          <div className="sr-combo-inputs-row">
            {/*  <CardElement
              className="sr-input sr-card-element"
              options={options}
            /> */}
            <CardExpiryElement
              className="sr-input sr-card-element"
              options={options}
            />
            <CardCvcElement
              className="sr-input sr-card-element"
              options={options}
            />
          </div>
{/*           <FormControlLabel
            style={{
              textAlign: "justify",
              margin: "8px 0",
              alignItems: "start",
            }}
            control={
              <Checkbox
                style={{ color: "var(--primary-color)", marginTop: "-8px" }}
                name="policy"
                checked={isAgree}
                onChange={(e) => setIsAgree(e.target.checked)}
              />
            }
            label={
              <Typography variant="caption">
                By clicking 'Checkout' you agree to our
                <Link
                  style={{ fontWeight: 600 }}
                  href="https://stripe.com/privacy"
                  target="_blank"
                >
                  {" "}
                  privacy policy
                </Link>{" "}
                and
                <Link
                  style={{ fontWeight: 600 }}
                  href="https://stripe.com/privacy"
                  target="_blank"
                >
                  {" "}
                  terms of service
                </Link>
                . You also agree to receive periodic email updates, discounts,
                and special offers.
              </Typography>
            }
          /> */}
        </div>

        {error && <div className="message sr-field-error">{error}</div>}
        <div className="fixed-in-mob">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              processing ||
              !clientSecret ||
              !stripe ||
              !isAgree ||
              !props?.isFormValid
            }
          >
            {processing ? "Processing…" : `Pay $${props.amount}`}
          </Button>
        </div>
      </form>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {renderForm()}
      </div>
    </div>
  );
}
