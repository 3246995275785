import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getCenterByKey, getOwnerOtherCenters, closeBooking } from "../actions";

import Header from "../components/Header";
import SpaInfo from "../components/SpaInfo";
import About from "../components/About";
import Footer from "../components/Footer";

import BookingModal from "./BookingModal";
import { imageUrl } from "../api/helpers";
import BookingPopup from "../components/BookingPopup";
import SalonPolicyModal from "../components/SalonPolicyModal";

const Center = (props) => {
  const { params } = props.match || {};
  const searchPrams = new URLSearchParams(props.location.search);
  const search_prams_rwg_token = searchPrams.get("_rwg_token");
  const {
    id,
    website_link = "",
    center_name = "",
    address = "",
    tel = "",
    about_us = "",
    logo_img = "",
    slider_images_list = [],
    rating = 0,
    rating_count = 0,
  } = props.center || {};

  const [bookNow, setBookNow] = useState(false);
  const [isManageBooking, setIsManageBooking] = useState(1);
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const [popupImage, setPopupImage] = useState(null);
  const [salonPolicy, setSalonPolicy] = useState(null);

  const handleClosePopup = useCallback(() => {
    const images = props?.center?.setting?.booking_img_popup;
    let index = images.indexOf(popupImage);
    if (index !== -1) {
      setPopupImage(images[index + 1] || null);
    }
  }, [props?.center?.setting?.booking_img_popup, popupImage]);

  useEffect(() => {
    if (props?.center?.setting?.color_code) {
      const color = props?.center?.setting?.color_code;
      const r = parseInt(color.substring(1, 3), 16);
      const g = parseInt(color.substring(3, 5), 16);
      const b = parseInt(color.substring(5, 7), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      const contrast = yiq >= 128 ? "black" : "white";

      document.documentElement.style.setProperty("--main-color", color);
      document.documentElement.style.setProperty("--main-color2", color);
      document.documentElement.style.setProperty("--primary-color", color);
      document.documentElement.style.setProperty("--background", color);
      document.documentElement.style.setProperty("--contrast", contrast);
    }
  }, [props?.center?.setting?.color_code]);

  useEffect(() => {
    props.dispatch(getCenterByKey(params.key));
    if (!props.owner_other_centers.length) {
      props.dispatch(getOwnerOtherCenters(params.key));
    }
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props?.center?.setting?.booking_img_popup?.length > 0) {
      setPopupImage(props?.center?.setting?.booking_img_popup[0]);
    }
  }, [props?.center?.setting?.booking_img_popup]);

  useEffect(() => {
    const init = () => {
      let merchantId = props?.center?.id + "";
      let merchantChanged = 2;
      let oldCookie = document.cookie;
      let oldMerchantId = null;
      if (oldCookie) {
        oldMerchantId = oldCookie
          ?.split("; ")
          .find((row) => row?.startsWith("_merchant_id="));
      }
      if (oldMerchantId) {
        oldMerchantId = oldMerchantId.split("=")[1];
      }
      if (!oldMerchantId || oldMerchantId != merchantId) {
        merchantChanged = 1;
      }
      if (search_prams_rwg_token) {
        if (search_prams_rwg_token && search_prams_rwg_token !== "null") {
          document.cookie = `_rwg_token=${search_prams_rwg_token};max-age=2592000;path=/`;
          document.cookie = `_merchant_id=${merchantId};max-age=2592000;path=/`;
        }
      }
      const newCookie = document.cookie;
      let rwgTokenVal = document.cookie;
      if (newCookie) {
        const rwgTokenCookie = newCookie
          ?.split("; ")
          .find((row) => row.startsWith("_rwg_token="));
        rwgTokenVal = rwgTokenCookie?.split("=")[1];
      }

      const conversionPartnerId =
        process.env.REACT_APP_GOOGLE_CONVERSION_PARTNER_ID;
      if (rwgTokenVal && rwgTokenVal !== "null" && conversionPartnerId) {
        const mode = process.env.REACT_APP_GOOGLE_RESERVE_MODE;
        let endpoint = "https://www.google.com/maps/conversion/debug/collect";
        if (mode === "production") {
          endpoint = "https://www.google.com/maps/conversion/collect";
        }
        try {
          fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
              conversion_partner_id: conversionPartnerId,
              rwg_token: rwgTokenVal,
              merchant_changed: merchantChanged,
            }),
          });
        } catch (error) {
          console.log(error, 564564654);
        }
      }
    };
    if (props?.center?.id) {
      init();
    }
  }, [search_prams_rwg_token, props?.center?.id]);

  const updateWindowDimensions = () => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  const onClose = () => {
    props.dispatch(closeBooking());
    setIsManageBooking(false);
    setBookNow(false);
  };

  const manageBooking = () => {
    setSalonPolicy(null);
    setIsManageBooking(true);
    setBookNow(true);
  };

  const launchBooking = () => {
    setSalonPolicy(props?.center?.setting?.salon_policy || null);
    setIsManageBooking(false);
    setBookNow(true);
  };

  return (
    <div className="main-wrapper center-wrapper">
      <Header
        website_link={website_link}
        bookNow={launchBooking}
        manageBooking={manageBooking}
        logoUrl={imageUrl(logo_img)}
        tel={tel}
      ></Header>
      <div className="center-main-content">
        <SpaInfo
          center_name={center_name}
          address={address}
          tel={tel}
          rating={rating}
          rating_count={rating_count}
          bookNow={launchBooking}
          slider_images_list={slider_images_list || []}
        ></SpaInfo>
        <About
          id={id}
          bookNow={launchBooking}
          about_us={about_us}
          rating={rating}
          rating_count={rating_count}
          address={address}
        ></About>
      </div>

      <Footer bookNow={launchBooking}></Footer>
      {!!bookNow && !salonPolicy && (
        <BookingModal
          show={bookNow}
          onClose={onClose}
          isManageBooking={isManageBooking}
          height={dimensions.height}
          width={dimensions.width}
        ></BookingModal>
      )}
      {!!bookNow && salonPolicy && (
        <SalonPolicyModal
          centerName={center_name}
          onClose={onClose}
          onContinue={() => setSalonPolicy(null)}
          policy={salonPolicy}
        />
      )}

      {popupImage && (
        <BookingPopup image={imageUrl(popupImage)} onClose={handleClosePopup} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => ({
  center: state.center || {},
  loading: state.app.loading,
  owner_other_centers:
    (state.owner &&
      state.owner.centers &&
      state.owner.centers.filter(
        (i) => i.store_key !== state.center.store_key
      )) ||
    [],
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Center));
