const initialState = {
  booking_id: null,
  count: 0,
  error: null
};

const booking = (state = {...initialState}, { type = '', payload = {} }) => {
    switch (type) {
        case 'BOOKING_SUCCESS':
            state = {
                ...state,
                booking_id: payload.booking_id,
                count: state.count + 1
            };
        break;
        case 'BOOKING_ERROR':
            state = {
              ...state,
              error: payload?.error || null,
            };
        break;

        case 'RE_INIT_BOOKING':
        case 'CLOSE_BOOKING':
            state = {
                ...initialState
            };
        break;

        case 'UPDATE_BOOKING_SUCCESS':
            state = {
                ...state,
                count: state.count + 1
            };
        break;

        case 'CHANGE_BOOKING':
            state = {
                ...state,
                booking_id: payload.booking_id
            };
        break;

        default:
        break;
    }
    
    return state;
};

export default booking;