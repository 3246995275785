import React from "react";
import { AppBar, Toolbar, Button } from "@material-ui/core";

const Header = React.memo((props) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <div className="container">
          <div className="header-row">
               <a
            href={props.website_link || ""}
            target="_blank"
            rel="noopener noreferrer"
            className="logo"
          >
            <img src={props.logoUrl || "/images/logo.png"} alt="" />
          </a>
          <div className="head-right">
            <a
              href={`tel:${props.tel}`}
              onClick={(e) => e.preventDefault()}
              className="call"
            >
              <svg
                className="contras-icon"
                height="20px"
                viewBox="0 0 20 20"
                width="20px"
                fill="#000000"
              >
                <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
              </svg>
              {props.tel}
            </a>
            <Button
              className="find-appointment-btn no-wrap"
              variant="outlined"
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                props.manageBooking(true);
              }}
            >
              Find Appointment
            </Button>
          </div>
          </div>
       
        </div>
      </Toolbar>
    </AppBar>
  );
});

export default Header;
