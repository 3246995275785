import requestService from '../services/requestService';

const createPaymentIntent = (data, query) => {
    const request = {
      method: 'post',
      url: `/payment/stripe`,
      params: {        
      },
      data
    };
  
    return requestService(request);

    //return {data: "test"};
  };

  export default {
    createPaymentIntent
  };