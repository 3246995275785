import React from 'react';
import StarRatings from 'react-star-ratings';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import duration from '../helpers/duration';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    extra: {
        marginLeft: 30
    }
}));

const Cart = React.memo(props => {
    const classes = useStyles();
    const { center, cart, editable } = props;
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let time = null;

    if (cart.date) {
        const h = cart.date.getHours() > 12 ? cart.date.getHours() - 12 : cart.date.getHours();
        const m = cart.date.getMinutes();
        let period = (cart.date.getHours() >= 12) ? 'pm' : 'am';
        time = `${h}:${('0'+m).slice(-2)} ${period}`;
    }

    return (
      <div className="popup-sidebar card">
        <div className="popup-sidebar-ttl-info">
          <h3 title={center.center_name}>{center.center_name}</h3>
          <span>{center.address}</span>
          <div className="rating-wrapp">
            <span>{center.rating}</span>
            <StarRatings
              rating={Number(center.rating_count) || 0}
              starRatedColor="#F46451"
              starEmptyColor="#DFE7EC"
              numberOfStars={5}
              name="rating"
              starDimension="21px"
              starSpacing="0"
            />
            <span>({center.rating_count} Reviews)</span>
          </div>
        </div>
        {cart.employee.hasOwnProperty("username") && (
          <div className="popup-sidebar-time">
            {cart.date && (
              <label>
                {`${cart.date.getDate()} ${
                  months[cart.date.getMonth()]
                } ${cart.date.getFullYear()}`}{" "}
                <strong>at</strong> {time}
              </label>
            )}
            <p>
              <strong>with </strong>
              {cart?.employee?.username?.toUpperCase()}
            </p>
          </div>
        )}
        <div className="popup-sidebar-tbl">
          {!cart.item.length ? (
            <div className="no-service-msg">No services selected</div>
          ) : (
            <table>
              <tbody>
                {cart.item.map((i, index) => (
                  <tr key={index}>
                    <td className={center.is_service_price ? "" : "tl"}>
                      <label className="truncate">{i.name}</label>
                      {center.is_duration && (
                        <span>{duration(i.duration)}</span>
                      )}
                    </td>
                    {!!center.is_service_price && (
                      <td>
                        <label className="action-and-price">
                          $ {i.price}{" "}
                          {editable ? (
                            <IconButton
                              disabled={!!i?.isDisable}
                              aria-label="delete"
                              className={classes.margin}
                              onClick={() => props.remove(i.id)}
                            >
                              {!i?.isDisable && (
                                <DeleteIcon
                                  fontSize="small"
                                  style={{ color: "#f50057" }}
                                />
                              )}
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </label>
                      </td>
                    )}
                  </tr>
                ))}
                {cart?.deposit_amount > 0 && (
                  <tr className="deposit-amount">
                    <td align="left" colSpan={2}>
                      <label>
                        {`You have deposited the amount of $${cart?.deposit_amount} for your this booking`}
                      </label>
                    </td>
                  </tr>
                )}

                {!!(cart.item.length && center.is_service_price) && (
                  <tr className="ttl-amt">
                    {center.is_total_online ? (
                      <>
                        <td>
                          {center.is_tax_online && <label>Taxes</label>}
                          <strong>Total</strong>
                          {center.is_duration && (
                            <span>{duration(cart.duration)}</span>
                          )}
                        </td>
                        <td>
                          {center.is_tax_online && (
                            <label>
                              <span className="no-wrap">
                                $ {cart.taxAmount}{" "}
                              </span>{" "}
                              {editable ? (
                                <IconButton
                                  className={classes.extra}
                                ></IconButton>
                              ) : (
                                ""
                              )}
                            </label>
                          )}
                          <strong className="no-wrap">
                            $ {cart.total}{" "}
                            {editable ? (
                              <IconButton
                                className={classes.extra}
                              ></IconButton>
                            ) : (
                              ""
                            )}
                          </strong>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          {center.is_duration && (
                            <span>{duration(cart.duration)}</span>
                          )}
                        </td>
                        <td></td>
                      </>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    ); 
});

export default Cart;