import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  getServices,
  addToCart,
  removeFromCart,
  clearCart,
  getEmployees,
  selectEmployee,
  selectDate,
  createBooking,
  reInitBooking,
  cancelBooking,
  updateBooking,
  changeBooking,
  updateCustomer,
  groupBooking,
  bookingError,
} from "../actions";

import BookingHeader from "../components/BookingHeader";
import BookingStep1 from "../components/BookingStep1";
import BookingStep2 from "../components/BookingStep2";
import BookingStep3 from "../components/BookingStep3";
import BookingStep4 from "../components/BookingStep4";
import BookingStep5 from "../components/BookingStep5";
import BookingConfirmModal from "../components/BookingConfirmModal";
import ConfirmModal from "../components/ConfirmModal";
import api from "../api";
import { useSocket } from "../components/context/SocketIOContext";

const renderStep = (param, step) => {
  switch (step) {
    case 1:
      return (
        <BookingStep1
          loading={param.loading}
          center={param.center}
          services={param.services}
          cart={param.cart}
          handleCart={param.handleCart}
        ></BookingStep1>
      );

    case 2:
      return (
        <BookingStep2
          loading={param.loading}
          center={param.center}
          cart={param.cart}
          employees={param.employees}
          handleCart={param.handleCart}
          onNext={param.handleNext}
          handleSelectEmployee={param.handleSelectEmployee}
        ></BookingStep2>
      );

    case 3:
      return (
        <>
          <BookingStep3
            center={param.center}
            cart={param.cart}
            employees={param.employees}
            booking={param.booking}
            handleSelectTimeSlot={param.handleSelectTimeSlot}
            handleBack={param.handleBack}
            isRescheduleMode={param.isRescheduleMode}
          ></BookingStep3>
        </>
      );

    case 4:
      return (
        <BookingStep4
          center={param.center}
          cart={param.cart}
          handleBooking={param.handleBooking}
          isManageBooking={param.isManageBooking}
          updateCustomer={param.handleUpdateCustomer}
          handleNext={param.handleNext}
          onAddOtherPerson={param.handleAddPersonBooking}
          isGroupBooking={param.isGroupBooking}
        ></BookingStep4>
      );

    case 5:
      return (
        <BookingStep5
          center={param.center}
          cart={param.cart}
          booking={param.booking}
          isNewBooking={param.isNewBooking}
          newBooking={param.newBooking}
          handleCancel={param.handleCancel}
          handleEdit={param.handleEdit}
          handleGroupBooking={param.handleGroupBooking}
          isGroupBookingCompleted={param.isGroupBookingCompleted}
          isManageBooking={param.isManageBooking}
          isShowUpcoming={param.isShowUpcoming}
          handleChangeBooking={param.handleChangeBooking}
        ></BookingStep5>
      );

    default:
      break;
  }
};

const BookingModal = (props) => {
  const {
    onEmitUpdateBooking,
    onEmitCreateBooking,
    onEmitNotificationCountUpdate,
  } = useSocket();
  const { center = {}, booking = {} } = props || {};
  const [step, setStep] = useState(props.isManageBooking ? 4 : 1);
  const [isManageBooking, setIsManageBooking] = useState(props.isManageBooking);
  const [isGroupBooking, setIsGroupBooking] = useState(false);
  const [isGroupBookingCompleted, setIsGroupBookingCompleted] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isShowUpcoming, setIsShowUpcoming] = useState(props.isManageBooking);
  const [isNewBooking, setIsNewBooking] = useState(!props.isManageBooking);
  const [isRescheduleMode, setIsRescheduleMode] = useState(false);
  const [isServiceLimitRich, setIsServiceLimitRich] = useState(false);
  const [countsForDeposits, setCountsForDeposits] = useState(null);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  useEffect(() => {
    if (step === 1) props.dispatch(getServices(center.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.booking.booking_id) {
      handleNext(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.booking.count]);

  useEffect(() => {
    if (booking.booking_id && props.isManageBooking) {
      getEmployeesByService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.booking_id]);

  useEffect(() => {
    if (props.cart.item?.length > 0 && props.center.is_employee) {
      getEmployeesByService();
    }
  }, [props.cart.item, props.center.is_employee]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let time = null;
  if (props.cart.date) {
    const h =
      props.cart.date.getHours() > 12
        ? props.cart.date.getHours() - 12
        : props.cart.date.getHours();
    const m = props.cart.date.getMinutes();
    let period = props.cart.date.getHours() >= 12 ? "pm" : "am";
    time = `${h}:${("0" + m).slice(-2)} ${period}`;
  }

  const handleSelectTimeSlot = (date) => {
    props.dispatch(selectDate(date));
  };

  const handleSelectEmployee = async (selectedEmployee) => {
    props.dispatch(selectEmployee(selectedEmployee));
  };

  const getEmployeesByService = () => {
    const serviceArr = props.cart.item
      .map((i) => {
        if (!i.isDisable || !isGroupBooking) {
          return i.id;
        }
      })
      .filter(Boolean);
    let date = null;
    let time = null;
    if (props.cart.date) {
      date = `${props.cart.date.getFullYear()}-${(
        "0" +
        (props.cart.date.getMonth() + 1)
      ).slice(-2)}-${("0" + props.cart.date.getDate()).slice(-2)}`;
      time = `${props.cart.date.getHours()}:${props.cart.date.getMinutes()}`;
    }
    if (serviceArr?.length > 0) {
      props.dispatch(getEmployees(serviceArr, props.center.id, date, time));
    }
  };

  const handleCart = (action, sid) => {
    if (action === "add") {
      if (props.cart.item.length < 6) {
        const toAdd = props.services
          .map((cat) => {
            return cat.services.filter((service) => service.id === sid);
          })
          .filter((i) => i.length)[0];

        toAdd.length &&
          props.dispatch(
            addToCart({ item: toAdd[0], is_tax_online: center.is_tax_online })
          );
      }
      //  else {
      //   setIsServiceLimitRich(true);
      // }
    } else {
      props.dispatch(
        removeFromCart({ sid, is_tax_online: center.is_tax_online })
      );
    }
  };

  const handleNext = async (currentStep = step, data) => {
    let nextStep = currentStep;

    switch (currentStep) {
      case 1:
        if (props.cart.item.length) {
          if (props.center.is_employee) {
            //getEmployeesByService();

            nextStep = 2;
          } else {
            handleSelectEmployee({
              id: 0,
              username: "anyone",
              job_title: "Maximum Availability",
            });

            if (isGroupBooking) {
              setIsNewBooking(false);
              if (
                !props?.cart?.deposit_amount &&
                props.cart?.customer_id &&
                props.center.is_service_amount &&
                props.cart.total >= props.center.service_amount
              ) {
                console.log(
                  `Reason for booking total more then ${props.center.service_amount}`
                );
                nextStep = 4;
              } else {
                await handleAddPersonBooking();
                return;
                //nextStep = 4;
              }
            } else {
              nextStep = 3;
            }
          }
        }
        break;

      case 2:
        if (
          props.cart.employee.hasOwnProperty("id") &&
          (props.employees.length || data.isRescheduleMode)
        ) {
          if (isGroupBooking) {
            setIsNewBooking(false);
            if (
              !props?.cart?.deposit_amount &&
              props.cart?.customer_id &&
              props.center.is_service_amount &&
              props.cart.total >= props.center.service_amount
            ) {
              console.log(
                `Reason for booking total more then ${props.center.service_amount}`
              );
              nextStep = 4;
            } else if (
              !props?.cart?.deposit_amount &&
              props.center.today_booking &&
              countsForDeposits?.todayBookingCount >=
                props.center.number_today_booking
            ) {
              console.log(
                `Reason for deposit: Currently has ${countsForDeposits.count} group today`
              );
              nextStep = 4;
            } else if (
              !props?.cart?.deposit_amount &&
              props.center.upcoming_booking &&
              countsForDeposits?.upcomingBookingCount >=
                props.center.number_upcoming_booking
            ) {
              console.log(
                `Reason for deposit: Currently has ${countsForDeposits.count} group upcoming`
              );
              nextStep = 4;
              // nextStep = 5;
            } else {
              await handleAddPersonBooking();
              return;
            }
          } else {
            nextStep = 3;
          }
        }
        break;

      case 3:
        if (props.cart.date) {
          if (props.cart.customer_id) {
            setIsNewBooking(false);
            handleUpdateBooking();
          } else {
            nextStep = 4;
          }
        }
        break;

      case 4:
        if (props.booking.booking_id || isManageBooking) {
          setIsManageBooking(false);
          nextStep = 5;
        }
        break;

      default:
        break;
    }
    setStep(nextStep);
  };

  const handleBack = () => {
    let previousStep = step;
    switch (step) {
      case 2:
        props.dispatch(selectEmployee({}));
        previousStep = 1;
        break;

      case 3:
        props.dispatch(selectEmployee({}));
        handleSelectTimeSlot(null);
        if (props.center.is_employee) {
          previousStep = 2;
        } else {
          previousStep = 1;
        }
        break;

      case 4:
        handleSelectTimeSlot(null);
        previousStep = 3;
        break;

      default:
        break;
    }
    setStep(previousStep);
  };

  const handleBooking = async (
    customer_id,
    customer_name,
    note,
    deposit_amount,
    stripe_payment_intent_id,
    orderId
  ) => {
    const { center, cart } = props;
    const { date, employee } = cart;
    const d = new Date();
    const utc_offset = d.getTimezoneOffset() * -1;
    const data = {
      spa_center_id: center.id,
      customer_id,
      customer_name,
      date: `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${(
        "0" + date.getDate()
      ).slice(-2)}`,
      time: `${date.getHours()}:${date.getMinutes()}`,
      employee_id: employee.id,
      note,
      deposit_amount,
      stripe_payment_intent_id,
      utc_offset,
      order_id: orderId,
    };
    data.service_id = cart.item.map((i) => +i.id).join();
    const resp = await props.dispatch(createBooking(data));
    if (resp?.data?.booking_id) {
      onEmitCreateBooking(resp?.data?.booking_id);
      onEmitNotificationCountUpdate(center?.id);
    }
  };

  const handleAddPersonBooking = async (req = {}) => {
    if (isBookingLoading) {
      return;
    }
    setIsBookingLoading(true);
    const { booking, cart } = props;
    const { date, employee } = cart;
    const { booking_id } = booking;
    const d = new Date();
    const utc_offset = d.getTimezoneOffset() * -1;
    const data = {
      utc_offset,
      date: `${date?.getFullYear()}${("0" + (date?.getMonth() + 1))?.slice(
        -2
      )}${("0" + date?.getDate()).slice(-2)}`,
      time: `${date?.getHours()}:${date?.getMinutes()}`,
      employee_id: employee?.id || 0,
      ...req,
    };
    data.service_id = cart.item
      .map((i) => {
        if (!i.isDisable) {
          return +i?.id;
        }
      })
      .filter(Boolean)
      .join();

    const reap = await props.dispatch(groupBooking({ data, booking_id }));
    if (reap) {
      setIsGroupBookingCompleted(true);
      onEmitUpdateBooking(booking_id);
      setIsGroupBooking(false);
      setIsBookingLoading(false);
    }
    return true;
  };

  const newBooking = () => {
    props.dispatch(getServices(center.id));
    props.dispatch(reInitBooking());
    setStep(1);
  };

  const onClose = (e, r) => {
    if (r !== "backdropClick") {
      setStep(1);
      props.onClose();
    }
  };

  const handleCloseDialog = useCallback(
    (e) => {
      setIsGroupBookingCompleted(false);
      if ((isRescheduleMode || isGroupBooking) && step !== 5) {
        setIsNewBooking(false);
        setIsRescheduleMode(false);
        setIsGroupBooking(false);

        handleNext(4);
      } else {
        onClose(e);
      }
    },
    [isRescheduleMode, isGroupBooking, step, onClose]
  );

  const handleEdit = async () => {
    if (booking.booking_id) {
      setIsRescheduleMode(true);
      handleNext(2, { isRescheduleMode: true });
    }
  };

  const handleGroupBooking = () => {
    if (booking.booking_id) {
      if (!isManageBooking) {
        props.dispatch(getServices(center.id));
      }
      //props.dispatch(clearCart([]));
      props.dispatch(selectEmployee({}));
      setStep(1);
      setIsGroupBooking(true);
      setIsGroupBookingCompleted(false);
    }
  };

  const handleCancel = async () => {
    const { booking_id = null } = booking;
    const d = new Date();
    const utc_offset = d.getTimezoneOffset() * -1;
    if (booking.booking_id) {
      const reap = await props.dispatch(
        cancelBooking({ booking_id, utc_offset })
      );
      onEmitUpdateBooking(booking_id);
      onEmitNotificationCountUpdate(props?.center?.id);
      setIsCanceled(true);
      newBooking();
    }
  };

  const handleUpdateBooking = async () => {
    const { booking, cart } = props;
    const { date } = cart;
    const { booking_id } = booking;
    const d = new Date();
    const utc_offset = d.getTimezoneOffset() * -1;
    const data = {
      date: `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${(
        "0" + date.getDate()
      ).slice(-2)}`,
      time: `${date.getHours()}:${date.getMinutes()}`,
      utc_offset,
    };
    const reap = await props.dispatch(updateBooking({ data, booking_id }));
    if (reap) {
      onEmitUpdateBooking(booking_id);
    }
    setIsRescheduleMode(false);
  };

  const handleChangeBooking = (detail) => {
    props.dispatch(changeBooking(detail));
  };

  const handleUpdateCustomer = (customer_id, customer_name) => {
    props.dispatch(updateCustomer({ customer_id, customer_name }));
  };

  const getDepositCount = useCallback(() => {
    if (
      !props?.cart?.deposit_amount &&
      props.cart?.customer_id &&
      booking?.booking_id
    ) {
      const today = new Date();
      const utc_offset = today.getTimezoneOffset() * -1;
      api
        .get("/customer/deposit-count/:id", {
          params: {
            id: props.cart?.customer_id,
            center_id: props?.center?.id,
            utc_offset,
            booking_id: booking?.booking_id,
            hours: props.cart.date.getHours(),
            minutes: props.cart.date.getMinutes(),
          },
        })
        .then(({ data }) => {
          const sDate = props.cart.date;

          const cDay = ("0" + today.getDate()).slice(-2);
          const cMonth = ("0" + (today.getMonth() + 1)).slice(-2);
          const sDay = ("0" + sDate.getDate()).slice(-2);
          const sMonth = ("0" + (sDate.getMonth() + 1)).slice(-2);
          const selectedDate = new Date(
            `${sDate.getFullYear()}-${sMonth}-${sDay}`
          ).getTime();
          const currantDate = new Date(
            `${today.getFullYear()}-${cMonth}-${cDay}`
          ).getTime();

          if (selectedDate == currantDate) {
            setCountsForDeposits({
              todayBookingCount: Number(data?.todayBookingCount),
            });
          }
          if (selectedDate > currantDate) {
            setCountsForDeposits({
              upcomingBookingCount: Number(data?.upcomingBookingCount),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props?.cart, props.center, booking?.booking_id]);

  // useEffect(() => {
  //   if (
  //     props.cart.employee.hasOwnProperty("id") &&
  //     props.employees.length &&
  //     props.center.is_employee
  //   ) {
  //     handleNext();
  //   }
  // }, [props.cart.employee?.id, props?.center?.is_employee]);

  useEffect(() => {
    if (isGroupBooking) {
      getDepositCount();
    } else {
      setCountsForDeposits(null);
    }
  }, [isGroupBooking]);

  const showBookNow = () => {
    switch (step) {
      case 1:
        return isGroupBooking
          ? props.cart?.item?.filter((i) => !i.isDisable).length
          : props.cart?.item.length;

      case 2:
        return false;
        return (
          props.cart.employee.hasOwnProperty("id") && props.employees.length
          //&&
          // (isGroupBooking
          //   ? props.cart?.item?.filter((i) => !i.isDisable).length
          //   : props.cart?.item.length)
        );

      case 3:
        return props.cart.date;

      default:
        return false;
    }
  };

  return (
    <>
      <Dialog
        open={props.show}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="main-popup"
        maxWidth="lg"
        // disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <BookingHeader
          step={step}
          isManageBooking={isManageBooking}
          isRescheduleMode={isRescheduleMode}
          onClose={handleCloseDialog}
          handleBack={handleBack}
        ></BookingHeader>
        <DialogContent>
          {renderStep(
            {
              loading: props.loading,
              center: props.center || {},
              services: props.services || [],
              cart: props.cart || {},
              employees: props.employees || [],
              booking: props.booking || {},
              handleCart,
              handleSelectEmployee,
              handleNext,
              handleBack,
              handleSelectTimeSlot,
              handleBooking,
              newBooking,
              handleCancel,
              handleEdit,
              handleGroupBooking,
              handleChangeBooking,
              isManageBooking,
              isShowUpcoming,
              isNewBooking,
              isGroupBooking,
              isGroupBookingCompleted,
              isRescheduleMode,
              handleUpdateCustomer,
              handleAddPersonBooking,
            },
            step
          )}
        </DialogContent>
        <DialogActions>
          <Grid item xs={7} sm={7} md={7}>
            {!!(props.cart.item.length && step < 4) && (
              <div className="mob-data">
                <span className="slct-srvc">
                  {props.cart.item.length} services selected{" "}
                </span>
                {props.center.is_service_price && (
                  <span className="amt">${props.cart.total} </span>
                )}
                <div className="mob-data-btm">
                  <span>
                    {!!props?.cart?.employee?.hasOwnProperty("username") && (
                      <React.Fragment>
                        with{" "}
                        <strong>
                          {props?.cart?.employee?.username?.toUpperCase()}
                        </strong>
                      </React.Fragment>
                    )}
                    {!!props.cart.date && (
                      <React.Fragment>
                        {" "}
                        on{" "}
                        <strong>
                          {" "}
                          {`${props.cart.date.getDate()} ${
                            months[props.cart.date.getMonth()]
                          }`}
                          ,
                        </strong>{" "}
                        <strong>{time}</strong>{" "}
                      </React.Fragment>
                    )}
                  </span>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={5} sm={5} md={5} className="btn-book-now-wrapp">
            {!!showBookNow() && (
              <div>
                {/* {booking.error && (
                  <Alert
                    onClose={() => props.dispatch(bookingError(null))}
                    severity="error"
                  >
                    {booking.error}
                  </Alert>
                )} */}

                <Button
                  variant="contained"
                  className="btn-blue btn-booknow"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNext();
                  }}
                >
                  {" "}
                  BOOK NOW{" "}
                </Button>
              </div>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
      {isCanceled && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={true}
          autoHideDuration={4000}
          onClose={() => setIsCanceled(false)}
        >
          <Alert onClose={() => setIsCanceled(false)} severity="success">
            Booking successfully canceled
          </Alert>
        </Snackbar>
      )}
      {booking?.error && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={true}
          autoHideDuration={4000}
          onClose={() => props.dispatch(bookingError(null))}
        >
          <Alert
            onClose={() => props.dispatch(bookingError(null))}
            severity="error"
          >
            {booking?.error}
          </Alert>
        </Snackbar>
      )}
      {isServiceLimitRich && (
        <ConfirmModal
          description={
            <p style={{ margin: 0, fontSize: "15px" }}>
              You only can book 6 services maximum on website. Please call{" "}
              <a
                style={{ color: "var(--primary-color)" }}
                href="tel: {props.center.tel}"
              >
                {props.center.tel}
              </a>{" "}
              if you want to book more.
            </p>
          }
          onClose={() => {
            setIsServiceLimitRich(false);
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => ({
  center: state.center || {},
  services: state.services || [],
  cart: state.cart || {},
  employees: state.employees || [],
  booking: state.booking || {},
  loading: state.app.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingModal);
