import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from "@material-ui/icons";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import useWindowDimensions from "./hooks/windowDimensions";

const SalonPolicyModal = memo(({ policy, centerName, onClose, onContinue }) => {
const { height, width } = useWindowDimensions();
  const [isAgree, setIsAgree] = useState(false);

const isMobile = useMemo(() => width <= 640, [width]);

  const handleChange = useCallback((e) => {
    setIsAgree(e?.target?.checked);
  }, []);

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      maxWidth={"md"}
      open={true}
      onClose={onClose}
    >
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="h6">{centerName} - Booking Policy</Typography>
        <IconButton
          className="close-btn"
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          {" "}
          <CloseIcon fontSize="large" />{" "}
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: policy }}></div>
      </DialogContent>
      <DialogActions className="policy-action">
        <FormControlLabel
          control={
            <Checkbox
              checked={isAgree}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="I agree with all terms and conditions mentioned in this policy."
        />
        <Button
          disabled={!isAgree}
          fullWidth={isMobile}
          variant="outlined"
          onClick={onContinue}
          className="btn-blue"
        >
          Continue Booking
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SalonPolicyModal;
