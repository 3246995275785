import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  makeStyles,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import clsx from "clsx";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { clearCart } from "../actions";
import Api from "../api";
import duration from "../helpers/duration";
import useWindowDimensions from "./hooks/windowDimensions";
import Loader from "./Loader";

const useStyles = makeStyles({
  icon: {
    borderRadius: "50%",
    width: 24,
    height: 24,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "var(--primary-color)",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "var(--primary-color)",
    },
  },
  dialogContent: {
    padding: "16px",
  },
});

const getAddOnService = (sid, cid) => {
  return Api.get("/service/add-on/:sid", { params: { sid, cid } });
};

const SelectAddOnServiceModal = memo((props) => {
  const { center } = props;
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 640, [width]);
  const [addOnServices, setAddOnServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    (e, service) => {
      const id = service?.id;
      // const item = props?.cart?.item.filter((i) => i.id === id)[0];
      // if (item && item?.isDisable) {
      //   return;
      // }

      const index = selectedServices.indexOf(id);
      if (selectedServices?.length >= 6 && index === -1) {
        props.onRichMaxLimit && props.onRichMaxLimit();
        return;
      } else if (index === -1) {
        selectedServices.push(id);
      } else {
        selectedServices.splice(index, 1);
      }
      setSelectedServices([...selectedServices]);
    },
    [selectedServices]
  );

  const handleAdd = useCallback(() => {
    //props.dispatch(clearCart([]));
    selectedServices.forEach((id) => {
      //const item = props.cart.item.filter((i) =>i.id === id)[0]
  
        props.handleCart("add", id);
      
    });
    props.onClose && props.onClose()
  }, [selectedServices]);

  // const handleChange = (e, id) => {
  //   props.handleCart(e.target.checked ? "add" : "remove", id);
  // };

  // const handleSubmit = useCallback(
  //   () => {

  //   },
  //   [],
  // )

  useEffect(() => {
     setSelectedServices(props.cart.item.map((i) => {
      if(!i?.isDisable){
          return i.id 
      } 
    }).filter(Boolean))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAddOnServices([]);
    if (props.serviceId) {
      setLoading(true);
      getAddOnService(props.serviceId, props.center.id).then(({ data }) => {
        setAddOnServices(data || []);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceId]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
      open={true}
      onClose={() => props.onClose()}
    >
      <DialogTitle disableTypography className="dialog-title">
        <Typography variant="h6">Add-on services</Typography>
        <IconButton
          className="close-btn"
          edge="start"
          color="inherit"
          onClick={() => props.onClose()}
          aria-label="close"
        >
          {" "}
          <CloseIcon fontSize="large" />{" "}
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className="list-of-radio-wrapp card">
          <RadioGroup aria-label="gender" name="customized-radios">
            <ul className="list-of-radio">
              {loading ? (
                <Loader />
              ) : (
                <>
                  {addOnServices?.map((service) => (
                    <li key={service.id}>
                      <FormControlLabel
                        value={service.id}
                        control={
                          <Checkbox
                            name={service?.name}
                            onChange={(e) => handleChange(e, service)}
                            checked={selectedServices.includes(service?.id)}
                            color="primary"
                            icon={<span className={classes.icon} />}
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                          />
                        }
                        label={service.name}
                      />
                      {!!center.is_service_price && (
                        <div className="amt p-2">$ {service.price}</div>
                      )}
                      {!!center.is_duration && (
                        <span className="time">
                          {duration(service.duration)}
                        </span>
                      )}
                      {service.description && <p>{service.description}</p>}
                    </li>
                  ))}
                </>
              )}
            </ul>
          </RadioGroup>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={() => props.onClose()}
          color="inherit"
        >
          Skip
        </Button>
        <Button
          style={{marginLeft: 20}}
          className="ml-5"
          variant="contained"
          onClick={handleAdd}
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapDispatchToProps)(SelectAddOnServiceModal);
