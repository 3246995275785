import api from '../api';

const serviceLoading = () => ({
    type: 'SERVICE_LOADING'
});

const serviceSuccess = category => ({
    type: 'SERVICE_SUCCESS',
    payload: category
});

// const addOnServiceSuccess = services => ({
//     type: 'ADD_ON_SERVICE_SUCCESS',
//     payload: services
// });

const serviceFail = () => ({
    type: 'SERVICE_FAIL'
});

const getServices = cid => {
    return dispatch => {
        dispatch(serviceLoading());
        api.get('/center/:cid/category/service', {
            params: { cid }
        }).then(res => {
            dispatch(serviceSuccess(res.data));
        }).catch(err => dispatch(serviceFail()));
    }
}

// const getAddOnServiceServices = sid => {
//     return dispatch => {
//         dispatch(serviceLoading());
//         api.get('/center/:cid/service/:sid', {
//             params: { cid }
//         }).then(res => {
//             dispatch(serviceSuccess(res.data));
//         }).catch(err => dispatch(serviceFail()));
//     }
// }

export {
    getServices
}