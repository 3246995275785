const httpResponse = (status, data) => {
  if (status === 500) {
    return { response: { status, data } };
  }
  return { status, data, statusText: "OK" };
};

const imageUrl = (url) => {
  if (!url) return null;
  if (url.startsWith("http")) {
    return url;
  } else {
    return `${process.env.REACT_APP_IMAGE_BASE_URL}${url}`;
  }
};

export { httpResponse, imageUrl };
