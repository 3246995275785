import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import {
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";

import Cart from "./Cart";
import StripeCheckoutForm from "./StripeCheckoutForm";
import api from "../api";
import { decrypt } from "../helpers/crpyto";

//const PayPalButton = window?.paypal?.Buttons.driver("react", { React, ReactDOM });

const renderStep = (param, currentStep) => {
  const { fields } = param;

  const dt = new Date();
  dt.setYear(dt.getFullYear() - 18);
  const maxDate = `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(
    -2
  )}-${("0" + dt.getDate()).slice(-2)}`;

  switch (currentStep) {
    case 1:
      return (
        <>
          <div className="form-box enter-phone-no">
            <h5>
              Please enter your phone number to{" "}
              {param.isManageBooking ? "find" : "complete"} booking.
            </h5>
            <div className="form-box-inside">
              <TextField
                placeholder="Phone Number"
                maxLength="10"
                value={fields.mobile.value}
                variant="outlined"
                onChange={(e) => param.handleChange(e, "mobile")}
                editable="false"
                disabled={!fields.bothAgree}
              />
              {!fields.mobile.isValid && (
                <small className="err">Invalid Mobile</small>
              )}
              {param.errMessage && (
                <small className="err">
                  {typeof param?.errMessage === "string"
                    ? param?.errMessage
                    : "There is no upcoming appointment assigned with phone number"}
                </small>
              )}

              <Button
                disabled={!fields.bothAgree}
                className="btn-blue"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  param.sendActivationCode();
                }}
              >
                {param.loading ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm"></span>
                    Loading...
                  </>
                ) : !param.isManageBooking ? (
                  "BOOK NOW"
                ) : (
                  "FIND APPOINTMENT"
                )}
              </Button>
            </div>

            <FormControlLabel
              style={{
                textAlign: "justify",
                marginTop: "20px",
                alignItems: "start",
              }}
              control={
                <Checkbox
                  style={{ color: "var(--primary-color)", marginTop: "-8px" }}
                  checked={fields.agreedPhone}
                  onChange={(e) => param.handleAgreePhoneCheckChange(e)}
                />
              }
              label={
                <Typography variant="body2">
                  By providing your phone number you hereby agree to sign up for
                  an account with our salon. You may be asked for additional
                  information regarding your account, such as your name, e-mail
                  address and other information related to your profile. We may
                  also use your phone number to send you service-related notices
                  such as special offers, feedback, notification.
                </Typography>
              }
            />
          </div>
        </>
      );

    case 2:
      return (
        <>
          <div className="form-box insert-activate-code">
            <h5>
              Please insert activation code sent to you by SMS to complete your
              booking.
            </h5>
            <div className="form-box-inside">
              <TextField
                maxLength="6"
                value={fields.activation_code.value}
                variant="outlined"
                name="activation_code"
                placeholder="Enter Activation Code"
                onChange={(e) => param.handleChange(e, "activation_code")}
              />
              {!fields.activation_code.isValid && (
                <small className="err">Invalid Code</small>
              )}
              {param.noAppointment && (
                <small className="err">{param.errMessage}</small>
              )}
              <Button
                className="btn-blue"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  param.activateMobile();
                }}
              >
                {param.loading ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm"></span>
                    Loading...
                  </>
                ) : (
                  "SUBMIT CODE"
                )}
              </Button>
              {!!param.activationCodeCount <= 3 && (
                <p className="form-txt-ctrl">
                  Didn't get Code?{" "}
                  <a
                    href="/#"
                    className="btn-link float-right color-blue"
                    onClick={(e) => {
                      e.preventDefault();
                      param.sendActivationCode();
                    }}
                  >
                    Resend SMS
                  </a>
                </p>
              )}
            </div>
          </div>
        </>
      );

    case 3:
      return (
        <>
          <div className="form-box personal-details-form">
            <h5>Please enter your personal profile to complete booking.</h5>
            <div className="form-box-inside">
              <form autoComplete="off">
                <label className="fm-ttl">
                  Full name{" "}
                  {!fields.name.isValid && (
                    <small className="err">This field is required</small>
                  )}
                </label>
                <TextField
                  variant="outlined"
                  name="name"
                  value={fields.name.value || ""}
                  placeholder="Enter your name"
                  onChange={(e) => param.handleChange(e, "name")}
                  disabled={!!fields.customer_id}
                />

                {!fields.customer_id && (
                  <>
                    <label className="fm-ttl">
                      Email{" "}
                      {fields.email_required ? "" : <small>(optional)</small>}{" "}
                      {!fields.email.isValid && (
                        <small className="err">
                          Please enter a valid email address
                        </small>
                      )}
                    </label>
                    <TextField
                      variant="outlined"
                      name="email"
                      value={fields.email.value || ""}
                      placeholder="Enter your email"
                      onChange={(e) => param.handleChange(e, "email")}
                    />
                  </>
                )}

                <label className="fm-ttl">
                  Phone Number{" "}
                  {!fields.mobile.isValid && (
                    <small className="err">
                      Please enter a valid Mobile no.
                    </small>
                  )}
                </label>
                <TextField
                  variant="outlined"
                  name="mobile"
                  value={fields.mobile.value || ""}
                  placeholder="Enter your phone number"
                  onChange={(e) => param.handleChange(e, "mobile")}
                  disabled={true}
                />

                {!fields.customer_id && (
                  <>
                    <label className="fm-ttl">
                      Your Birthday <small>(optional)</small>{" "}
                      {!fields.birthday.isValid && (
                        <small className="err">
                          Please enter a valid Birthday
                        </small>
                      )}
                    </label>
                    <TextField
                      type="date"
                      variant="outlined"
                      name="birthday"
                      value={fields.birthday.value || ""}
                      placeholder="Enter your birthday"
                      onChange={(e) => param.handleChange(e, "birthday")}
                      inputProps={{ max: maxDate }}
                    />
                  </>
                )}

                {!fields.customer_id && (
                  <>
                    <label className="fm-ttl">
                      Referal Code <small>(optional)</small>{" "}
                      {!fields.referral_code.isValid && (
                        <small className="err">Invalid referral code</small>
                      )}
                    </label>
                    <TextField
                      variant="outlined"
                      name="referral_code"
                      value={fields.referral_code.value || ""}
                      placeholder="Enter referral code"
                      onChange={(e) => param.handleChange(e, "referral_code")}
                    />
                  </>
                )}
                <div className="display-in-xs step-4-cart">
                  <Cart center={param.center} cart={param.cart}></Cart>
                </div>

                <label className="fm-ttl">
                  Booking Notes <small>(optional)</small>
                </label>
                <TextField
                  variant="outlined"
                  multiline
                  rows={2}
                  name="notes"
                  value={fields.notes.value || ""}
                  placeholder="Enter notes for booking. Caution : Booking Notes can not be used to add more appointments. Please repeat Booking Steps if you need to add more people or Services."
                  onChange={(e) => param.handleChange(e, "notes")}
                />

                {fields.deposit.should_deposit === false && (
                  <div className="fixed-in-mob">
                    <Button
                      className="btn-blue"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        param.completeBooking();
                      }}
                    >
                      {param.loading ? (
                        <>
                          <span className="spinner-grow spinner-grow-sm"></span>
                          Loading...
                        </>
                      ) : (
                        "COMPLETE BOOKING"
                      )}
                    </Button>
                  </div>
                )}
                {fields.deposit.should_deposit === true && (
                  <label className="fm-ttl">
                    Please make a deposit of{" "}
                    <b>$ {Number(fields.deposit.amount).toFixed(2)}</b>
                  </label>
                )}
              </form>

              {fields.deposit.should_deposit === true && (
                <div>
                  {fields?.paymentGetaway?.paypal && (
                    <PayPalScriptProvider
                      options={{
                        "client-id": decrypt(
                          fields?.paymentGetaway?.paypal.public_key_client_id
                        ),
                        currency: fields?.paymentGetaway?.paypal?.currency,
                      }}
                    >
                      <PayPalButtons
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: fields.deposit.amount,
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={(data, actions) => {
                          console.log("data", data);
                          console.log("actions", actions);

                          param.onApprove(
                            data,
                            actions,
                            Number(Number(fields.deposit.amount).toFixed(2))
                          );
                          // return actions.order.capture().then((details) => {
                          // 	const name = details.payer.name.given_name;
                          // 	alert(`Transaction completed by ${name}`);
                          // });
                        }}
                      />
                    </PayPalScriptProvider>
                    // <PayPalButton
                    //   createOrder={(data, actions) =>
                    //     param.createOrder(data, actions)
                    //   }
                    //   onApprove={(data, actions) =>
                    //     param.onApprove(data, actions, fields.deposit.amount)
                    //   }
                    // />
                  )}
                  {fields?.paymentGetaway?.stripe && (
                    <Elements
                      stripe={loadStripe(
                        decrypt(
                          fields?.paymentGetaway?.stripe?.public_key_client_id
                        ) || process.env.REACT_APP_STRIPE_PK
                      )}
                    >
                      <StripeCheckoutForm
                        isFormValid={!!fields.name.value}
                        amount={fields.deposit.amount}
                        center={param.center}
                        onApprove={(data) => param.onStripeApprove(data)}
                      />
                    </Elements>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      );
    default:
      break;
  }
};

const getCustomer = (mobile, req) => {
  const d = new Date();
  const utc_offset = d.getTimezoneOffset() * -1;
  return api.get("/customer/mobile/:mobile", {
    params: { mobile, ...req, utc_offset },
  });
};

const getPaymentGateway = (center_id) => {
  return api.get("/center/:id/payment-gateway", {
    params: { id: center_id },
  });
};

const addCustomer = (data) => {
  return api.post("/customer", data);
};

const BookingStep4 = React.memo((props) => {
  const formFields = {
    customer_id: null,
    name: {
      isValid: true,
      value: "",
    },
    mobile: {
      isValid: true,
      value: "",
    },
    notes: {
      isValid: true,
      value: "",
    },
    activation_code: {
      isValid: true,
      value: "",
    },
    email: {
      isValid: true,
      value: "",
    },
    birthday: {
      isValid: true,
      value: "",
    },
    referral_code: {
      isValid: true,
      value: "",
    },
    agreedPhone: true,
    bookingPolicy: true,
    bothAgree: true,
    email_required: props.center.email_required_when_booking,
    deposit: {
      no_show_check: props.center.no_show, // Có kiểm tra no show không
      today_booking_check: props.center.today_booking, // Có kiểm tra số lượng booking hôm nay không
      upcoming_booking_check: props.center.upcoming_booking, // Có kiểm tra sắp tới có booking hay không
      upcoming_booking_check: props.center.upcoming_booking, // Có kiểm tra sắp tới có booking hay không
      amount: props.center?.amount, // Số tiền phải deposit
      option: props.center?.option, // Bao nhiêu bữa noshow thì bắt deposit

      should_deposit: false,
    },
  };
  const [step, setStep] = useState(1);
  const [token, setToken] = useState(null);
  const [fields, setFields] = useState(formFields);
  const [errMessage, setErrMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activationCodeCount, setActivationCodeCount] = useState(0);

  const handleChange = (e, fieldName) => {
    const stateFields = fields;
    const maxLength = 10;
    const value =
      fieldName === "mobile"
        ? e.target.value.replace(/[^0-9]/g, "")
        : e.target.value;
    if (stateFields.hasOwnProperty(fieldName)) {
      if (fieldName === "mobile") {
        setErrMessage("");
        stateFields[fieldName].value =
          value.length > maxLength ? value.slice(0, maxLength) : value;
      } else {
        stateFields[fieldName].value = value;
      }
    }
    setFields({ ...stateFields });
  };

  const handleAgreePhoneCheckChange = (e) => {
    const stateFields = fields;
    stateFields.agreedPhone = !stateFields.agreedPhone;
    stateFields.bothAgree =
      stateFields.agreedPhone && stateFields.bookingPolicy;
    setFields({ ...stateFields });
  };

  const handleBookingPolicyChange = (e) => {
    const stateFields = fields;
    stateFields.bookingPolicy = !stateFields.bookingPolicy;
    stateFields.bothAgree =
      stateFields.agreedPhone && stateFields.bookingPolicy;
    setFields({ ...stateFields });
  };

  const validateFields = (fieldsToValidate) => {
    const stateFields = fields;
    let count = fieldsToValidate.length;
    for (let key in stateFields) {
      if (stateFields.hasOwnProperty(key) && fieldsToValidate.includes(key)) {
        if (key !== "customer_id") stateFields[key].isValid = false;
        switch (key) {
          case "name":
            if (stateFields[key].value) {
              stateFields[key].isValid = true;
              count--;
            }
            break;

          case "mobile":
            const regex = RegExp(/^[1-9]\d{9}$/);
            stateFields[key].isValid = regex.test(stateFields[key].value);
            if (stateFields[key].isValid) {
              count--;
            }
            break;

          case "notes":
            stateFields[key].isValid = true;
            count--;
            break;

          case "activation_code":
            if (stateFields[key].value) {
              stateFields[key].isValid = true;
              count--;
            }
            break;

          case "email":
            if (formFields.email_required) {
              stateFields[key].isValid =
                !stateFields[key].value ||
                /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
                  stateFields[key].value
                );
              if (stateFields[key].isValid) {
                count--;
              }
            } else {
              stateFields[key].isValid = true;
              count--;
            }
            break;

          case "birthday":
            stateFields[key].isValid = true;
            count--;
            break;

          case "referral_code":
            stateFields[key].isValid = true;
            count--;
            break;

          default:
            break;
        }
      }
    }
    setFields({ ...stateFields });
    return count <= 0;
  };

  const sendActivationCode = () => {
    const fieldsToValidate = ["mobile"];
    if (!validateFields(fieldsToValidate)) return false;

    const center_id = props.center.id;
    const activatedMob = JSON.parse(
      localStorage.getItem("activated_mob") || "{}"
    );
    const centerActivatedMobiles = activatedMob[center_id] || [];

    if (centerActivatedMobiles.includes(fields.mobile.value)) {
      const stateFields = fields;
      stateFields.activation_code.value = "1234";
      setFields(stateFields);
      return afterActivation();
    }

    if (activationCodeCount > 3) return false;
    if (loading) {
      return;
    }
    setLoading(true);

    const d = new Date();
    const utc_offset = d.getTimezoneOffset() * -1;

    return api
      .post("/customer/send-activation", {
        mobile: fields.mobile.value,
        cid: props.center.id,
        source: props.isManageBooking ? "manage_booking" : "new_booking",
        utc_offset,
      })
      .then((res) => {
        const { data } = res;
        setActivationCodeCount(activationCodeCount + 1);
        setLoading(false);

        if (props.isManageBooking && (!data.count || data.count <= 0)) {
          const err = `There is no upcoming appointment assigned with phone number ${fields.mobile.value}`;
          setErrMessage(err);
        } else {
          setToken(data.token);
          setStep(2);
        }
      })
      .catch((err) => {
        const { response = {} } = err.response?.data;

        if (response.status === 400) {
          setErrMessage(response.data.message);
        }
      });
  };

  const activateMobile = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    return api
      .post("/customer/verify-otp", {
        mobile: fields.mobile.value,
        cid: props.center.id,
        token,
        otp: fields.activation_code.value,
      })
      .then((res) => {
        try {
          setLoading(false);
          const center_id = props.center.id;
          let activatedMob = JSON.parse(
            localStorage.getItem("activated_mob") || "{}"
          );
          if (Array.isArray(activatedMob)) {
            activatedMob = {};
          }
          const centerActivatedMobiles = activatedMob[center_id] || [];
          if (!centerActivatedMobiles.includes(fields.mobile.value)) {
            centerActivatedMobiles.push(fields.mobile.value);
            activatedMob[center_id] = centerActivatedMobiles;
            localStorage.setItem("activated_mob", JSON.stringify(activatedMob));
          }
          afterActivation();
        } catch (e) {
          setLoading(false);
          setFields({
            ...fields,
            activation_code: { ...fields.activation_code, isValid: false },
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        setFields({
          ...fields,
          activation_code: { ...fields.activation_code, isValid: false },
        });
      });
  };

  const afterActivation = () => {
    const stateFields = fields;
    const err = `There is no upcoming appointment assigned with phone number ${fields.mobile.value}`;

    getCustomer(fields.mobile.value, {
      center_id: props.center.id,
      hours: props?.cart?.date ? props.cart.date.getHours() : null,
      minutes: props?.cart?.date ? props.cart.date.getMinutes() : null,
    })
      .then((res) => {
        const { data } = res;
        const dt = new Date(data.birthday);
        stateFields.name.value = data.fullname;
        stateFields.email.value = data.email;
        stateFields.birthday.value = data.birthday
          ? `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${(
              "0" + dt.getDate()
            ).slice(-2)}`
          : "";
        stateFields.customer_id = data.id;

        if (props?.cart?.date) {
          var today = new Date();
          var sDate = props.cart.date;
          var todayCount = 0;
          const cDay = ("0" + today.getDate()).slice(-2);
          const cMonth = ("0" + (today.getMonth() + 1)).slice(-2);
          const sDay = ("0" + sDate.getDate()).slice(-2);
          const sMonth = ("0" + (sDate.getMonth() + 1)).slice(-2);
          const selectedDate = new Date(
            `${sDate.getFullYear()}-${sMonth}-${sDay}`
          ).getTime();
          const currantDate = new Date(
            `${today.getFullYear()}-${cMonth}-${cDay}`
          ).getTime();

          // for(var i = 0; i < data.bookings.length; i++) {
          // 	var booking = data.bookings[i];
          // 	var date_utc = new Date(`${booking.selected_date} ${booking.apointed_time} UTC`);

          // 	var today_object =  Date.UTC(today.getUTCFullYear(),
          // 		today.getUTCMonth(), today.getUTCDate(),
          // 		today.getUTCHours(), today.getUTCMinutes()
          // 	);
          // 	var today_utc = new Date(today_object);

          // 	//console.log(date_utc);
          // 	//console.log(today_utc);

          // 	if (today_utc.getDay() === date_utc.getDay() &&
          // 		today_utc.getMonth() === date_utc.getMonth() &&
          // 		today_utc.getFullYear() === date_utc.getFullYear()) {
          // 			todayCount++;
          // 	}
          // }

          // Xử lí thông tin noshow, current_booking, upcoming_booking ở đây
          // Có xử lí noshow không?
          if (
            props.center.no_show &&
            data.no_show >= props.center.number_no_show
          ) {
            stateFields.deposit.should_deposit = true;
            console.log(
              `Reason for deposit: current no show ${data?.no_show} times >= ${props.center.number_no_show}`
            );
          } else if (
            props.center.today_booking &&
            data.counts.todayBookingCount >=
              props.center.number_today_booking &&
            selectedDate === currantDate
          ) {
            stateFields.deposit.should_deposit = true;
            console.log(
              `Reason for deposit: Currently has ${data.counts.todayBookingCount} booking details same time today`
            );
          } else if (
            props.center.upcoming_booking &&
            (data.counts.upcomingBookingCount >=
              props.center.number_upcoming_booking ||
              data?.counts?.upcoming >= props.center.number_upcoming_booking) &&
            selectedDate > currantDate
          ) {
            stateFields.deposit.should_deposit = true;
            console.log(
              `Reason for deposit: Has ${data.counts.upcomingBookingCount} upcoming booking details at same time after same today OR count of upcoming booking ${data?.counts?.upcoming}`
            );
          } else if (
            props.center.new_customer &&
            data.counts.newCustomer &&
            props.cart.total >= (props.center.new_customer_number_booking || 0)
          ) {
            stateFields.deposit.should_deposit = true;
            console.log(`Reason for deposit:new customer booking`);
          } else if (
            props.center.is_service_amount &&
            props.cart.total >= props.center.service_amount
          ) {
            stateFields.deposit.should_deposit = true;
            console.log(
              `Reason for booking total more then ${props.center.service_amount}`
            );
          }

          if (stateFields.deposit.should_deposit) {
            stateFields.deposit.amount = props.center.amount;
            if (props.center.amount == 0 && props.center.percent > 0) {
              stateFields.deposit.amount =
                props.cart.total * props.center.percent;
            }
          }
        }

        // if (
        //   props.center.new_customer &&
        //   data.counts?.newCustomer >=
        //     (props.center.new_customer_number_booking || 0)
        // ) {
        //   setFields(stateFields);
        // }
        // setFields(stateFields);
        if (props.isManageBooking) {
          props.updateCustomer(data.id, data.fullname);
          if (data.counts.upcoming > 0) {
            props.handleNext(4);
          } else {
            setErrMessage(err);
          }
        } else setStep(3);
      })
      .catch((err) => {
        console.log(err);
        const { response = {} } = err?.response?.data;
        if (
          props.center.new_customer &&
          props.cart.total >= (props.center.new_customer_number_booking || 0) &&
          !props.isManageBooking
        ) {
          stateFields.deposit.should_deposit = true;
          console.log(`Reason for deposit:new customer booking`);
        }

        if (
          props.center.is_service_amount &&
          props.cart.total >= (props.center.service_amount || 0) &&
          !props.isManageBooking
        ) {
          stateFields.deposit.should_deposit = true;
          console.log(
            `Reason for booking total more then ${props.center.service_amount}`
          );
        }

        if (stateFields.deposit.should_deposit) {
          stateFields.deposit.amount = props.center.amount;
          if (props.center.amount == 0 && props.center.percent > 0) {
            stateFields.deposit.amount =
              props.cart.total * props.center.percent;
          }
        }

        if (response.status === 400) {
          setErrMessage(response.data.message);
          return;
        }
        if (props.isManageBooking) {
          setErrMessage(err);
        } else setStep(3);
      });
  };

  const handleGroupBookingPay = useCallback(() => {
    const stateFields = fields;
    getCustomer(1234567890, {
      center_id: props.center.id,
      customer_id: props.cart?.customer_id,
    })
      .then((resp) => {
        const { data } = resp;
        const dt = new Date(data.birthday);
        stateFields.mobile.value = data.tel;
        stateFields.name.value = data.fullname;
        stateFields.email.value = data.email;
        stateFields.birthday.value = data.birthday
          ? `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${(
              "0" + dt.getDate()
            ).slice(-2)}`
          : "";
        stateFields.customer_id = data.id;
        stateFields.deposit.should_deposit = true;

        stateFields.deposit.amount = props.center.amount;
        if (props.center.amount == 0 && props.center.percent > 0) {
          stateFields.deposit.amount = props.cart.total * props.center.percent;
        }
        setStep(3);
      })
      .catch((err) => {
        const { response = {} } = err?.response?.data;
        if (response.status === 400) {
          setErrMessage(response.data.message);
          return;
        }
      });
  }, [props.center, props.cart]);

  const completeBooking = async (ordId, deposit_amount = 0, extra = {}) => {
    console.log("ORDER ID IS ", ordId);
    const { stripe_payment_intent_id = null } = extra;

    if (props.isGroupBooking) {
      props.onAddOtherPerson({ deposit_amount, ordId });
      return;
    }
    if (loading) {
      return;
    }

    const fieldsToValidate = [
      "name",
      "mobile",
      "notes",
      "activation_code",
      "email",
      "birthday",
      "referral_code",
    ];

    if (!validateFields(fieldsToValidate)) return false;
    setLoading(true);

    if (fields.customer_id) {
      await props.handleBooking(
        fields.customer_id,
        fields.name.value,
        fields.notes.value,
        deposit_amount,
        stripe_payment_intent_id,
        ordId
      );
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      const {
        name: { value: name },
        mobile: { value: mobile },
        email: { value: email },
        birthday: { value: birthday },
        referral_code: { value: referral_code },
      } = fields;
      const center_id = props.center.id;
      const d = new Date();
      const utc_offset = d.getTimezoneOffset() * -1;

      addCustomer({
        name,
        mobile,
        email,
        birthday,
        referral_code,
        center_id,
        utc_offset,
      })
        .then((res) => {
          const { status, data } = res;
          if (status === 200 && data.hasOwnProperty("id")) {
            props.handleBooking(
              data.id,
              name,
              fields.notes.value,
              deposit_amount,
              ordId
            );
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          }
        })
        .catch((err) => {
          const stateFields = fields;
          stateFields.referral_code.isValid = false;
          setFields(stateFields);
          setLoading(false);
        });
    }
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            value: fields.deposit.amount, //get the amount from spaCenter.settings.deposit_amount
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
        brand_name: "Belle Booking (Spa Online)",
      },
    });
  };

  const onApprove = (data, actions, amount) => {
    // return actions.order.capture();

    completeBooking(data.orderID, amount);
  };

  const onStripeApprove = (data) => {
    let stripe_payment_intent_id = null;
    if (data?.capture_method === "manual") {
      stripe_payment_intent_id = data?.id;
    }
    completeBooking(null, data.amount / 100, { stripe_payment_intent_id });
  };

  useEffect(() => {
    if (props?.isGroupBooking && props.cart?.customer_id) {
      handleGroupBookingPay();
    }
  }, [props?.isGroupBooking, props.cart?.customer_id]);

  useEffect(() => {
    const init = () => {
      getPaymentGateway(props.center.id).then(({ data }) => {
        setFields((state) => {
          return {
            ...state,
            paymentGetaway: data,
          };
        });
      });
    };
    if (fields.deposit.should_deposit) {
      init();
    }
  }, [fields.deposit.should_deposit]);

  return (
    <Grid container spacing={8} className="popup-container">
      <Grid item xs={12} sm={12} md={7}>
        <div className="list-of-radio-wrapp mrgn-top-30">
          {renderStep(
            {
              handleBookingPolicyChange,
              handleAgreePhoneCheckChange,
              handleChange,
              sendActivationCode,
              activateMobile,
              completeBooking,
              fields,
              errMessage,
              loading,
              activationCodeCount,
              isManageBooking: props.isManageBooking,
              advanceHourCancelEdit:
                props.center.advance_hour_cancel_reschedule_online,
              createOrder,
              onApprove,
              onStripeApprove,
              center: props.center,
              cart: props.cart,
              //depositAmount: "5.0"
            },
            step
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={5} className="hide-in-mob">
        <div className="mrgn-top-30">
          <Cart center={props.center} cart={props.cart}></Cart>
        </div>
      </Grid>
    </Grid>
  );
});

export default BookingStep4;
