import {
  Button,
  TextField,
} from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateBookingNote } from "../actions";
import DefaultDialog from "./context/Dialog";
import { useSocket } from "./context/SocketIOContext";


const UpdateNoteModal = memo((props) => {
  const [note, setNote] = useState("");
  const { onEmitUpdateBooking } = useSocket();

  const handleChange = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handleSave = useCallback(async () => {
    const d = new Date();
    const data = {
      utc_offset: d.getTimezoneOffset() * -1,
      date: props.booking.date,
      note,
    };
    await props.dispatch(
      updateBookingNote({ data, booking_id: props.booking.booking_id })
    );
    setTimeout(() => {
      onEmitUpdateBooking(props?.booking?.booking_id);
      props.onUpdate(note);
    }, 400);
  }, [note, props.booking]);

  useEffect(() => {
    setNote(props.booking.note);
  }, [props.booking.note]);

  return (
    <DefaultDialog
      title="Update Note"
      maxWidth="sm"
      onClose={() => props.onClose()}
      actionProps={{
        style: { justifyContent: "center" },
      }}
      actions={
        <Button
          variant="contained"
          onClick={handleSave}
          className="btn-blue"
        >
          SAVE
        </Button>
      }
    >
      <div style={{ padding: "16px 0" }}>
        <TextField
          variant="outlined"
          multiline
          placeholder="Enter Note"
          className="text-area-note"
          minRows={3}
          value={note || ""}
          onChange={handleChange}
        />
      </div>
    </DefaultDialog>
  );
});

const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapDispatchToProps)(UpdateNoteModal);
