import React, { useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  getOwnerCenters,
} from "../actions";

import OtherLocation from "../components/OtherLocation";
import Footer from "../components/Footer";


const Owner = (props) => {
  const { params } = props.match || {};
  // const navigate = useNavi;
  useEffect(() => {
    props.dispatch(getOwnerCenters(params.key));
    //updateWindowDimensions();
    //window.addEventListener("resize", updateWindowDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (props.centers.length) {
  //     props.dispatch(getCenterByKey(props.centers[0].store_key));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.centers.length]);



 // const [bookNow, setBookNow] = useState(false);
  //const [isManageBooking, setIsManageBooking] = useState(1);
  //const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  // const updateWindowDimensions = () => {
  //   setDimensions({ width: window.innerWidth, height: window.innerHeight });
  // };

  const handleClickCard = useCallback((item) => {
    let link = window.location.href;
    link = link.split("/owner")[0];
    window.open(`${link}/center/${item?.store_key}`, "_self");
  }, []);

  // const onClose = () => {
  //   props.dispatch(closeBooking());
  //   setIsManageBooking(false);
  //   setBookNow(false);
  // };

  // const manageBooking = () => {
  //   setIsManageBooking(true);
  //   setBookNow(true);
  // };

  const launchBooking = () => {
    // setIsManageBooking(false);
    // setBookNow(true);
  };

  return (
    <div className="main-wrapper">
      {/*         <Header
          website_link={website_link}
          bookNow={launchBooking}
          manageBooking={manageBooking}
          logoUrl={imageUrl(logo_img)}
          tel={tel}
        ></Header>
        <SpaInfo
          center_name={center_name}
          address={address}
          rating={rating}
          rating_count={rating_count}
          bookNow={launchBooking}
          slider_images_list={slider_images_list || []}
        ></SpaInfo>
        <About
          id={id}
          bookNow={launchBooking}
          about_us={about_us}
          rating={rating}
          rating_count={rating_count}
          address={address}
        ></About> */}
      <div className="owner-main-content">
        {!!props.owner_other_centers.length && (
          <OtherLocation
            onClick={handleClickCard}
            locations={props.owner_other_centers}
          />
        )}
      </div>

      <Footer bookNow={launchBooking} isOwner></Footer>
      {/* {!!bookNow && (
          <BookingModal
            show={bookNow}
            onClose={onClose}
            isManageBooking={isManageBooking}
            height={dimensions.height}
            width={dimensions.width}
          ></BookingModal>
        )} */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => ({
  centers: state.owner.centers || [],
  center: state.center || {},
  loading: state.app.loading,
  owner_other_centers:
    (state.owner &&
      state.owner.centers &&
      state.owner.centers.filter(
        (i) => i.store_key !== state.center.store_key
      )) ||
    [],
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Owner));
