import React, { useState, useEffect } from "react";
import StarRatings from "react-star-ratings";
import { Grid, RadioGroup, MenuItem, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Cart from "./Cart";
import Loader from "./Loader";
import { imageUrl } from "../api/helpers";
import { ChevronRight } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const BookingStep2 = React.memo((props) => {
  const [isSelectEmployee, setIsSelectEmployee] = useState(false);

  const anyone = {
    id: 0,
    username: "anyone",
    job_title: "Maximum Availability",
  };
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    setEmployees([...props.employees]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employees]);

  const selectEmployee = (id) => {
    let selectedEmployee = {};
    if (!id) {
      selectedEmployee = anyone;
    } else {
      selectedEmployee = employees.filter((i) => i.id === id)[0] || {};
    }
    setIsSelectEmployee(true);
    props.handleSelectEmployee(selectedEmployee);
    // if (props.cart?.item?.filter((i) => !i.isDisable).length) {

    // } else {
    //   return;
    // }

    // setTimeout(() => {
    // 	console.log(465465465)
    // 	props.onNext()
    // }, 500);
  };

  useEffect(() => {
    if (
      props.cart.employee.hasOwnProperty("id") &&
      props.employees.length &&
      props.center.is_employee &&
      isSelectEmployee
    ) {
      setIsSelectEmployee(false);
      props.onNext();
    }
  }, [props.cart.employee?.id, props?.center?.is_employee, isSelectEmployee]);

  const removeItem = (id) => {
    props.handleCart("remove", id);
  };

  return (
    <Grid container spacing={8} className="popup-container">
      <Grid item xs={12} sm={12} md={7}>
        <div className="list-of-radio-wrapp card">
          {props.employees.length > 0 && (
            <>
              <Typography className="p-3" variant="subtitle1">
                No Preference
              </Typography>
              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
              >
                <ul className="list-of-radio list-of-technicians">
                  <MenuItem
                    component="li"
                    className="item"
                    key={anyone?.id}
                    onClick={() => selectEmployee(anyone?.id)}
                  >
                    <div className="img-box">
                      <img
                        title={anyone?.username}
                        alt={anyone?.username || ""}
                        src={`/images/user-img.png`}
                      />
                    </div>
                    <div className="employee-list-item">
                      <span>{anyone?.username.toUpperCase()}</span>
                      <div className="left-job">
                        <div className="job">{anyone?.job_title}</div>
                        <ChevronRight />
                      </div>
                    </div>
                  </MenuItem>
                </ul>
              </RadioGroup>
            </>
          )}
          <Typography className="p-3 mrgn-top-20" variant="subtitle1">
            Request Staff
          </Typography>

          <RadioGroup
            defaultValue="female"
            aria-label="gender"
            name="customized-radios"
          >
            {props.employees.length ? (
              <ul className="list-of-radio list-of-technicians">
                {employees.map((emp) => (
                  <MenuItem
                    component="li"
                    className="item"
                    key={emp.id}
                    onClick={() => selectEmployee(emp.id)}
                  >
                    <div className="img-box">
                      <img
                        title={emp.username}
                        alt={emp.username}
                        src={
                          emp.avatar_link
                            ? imageUrl(emp.avatar_link)
                            : `/images/user-img.png`
                        }
                      />
                    </div>
                    <Grid container spacing={1.5}>
                      <Grid item xs={5}>
                        <Typography
                          style={{ display: "block" }}
                          component="span"
                          noWrap
                          variant="inherit"
                        >
                          {emp.username.toUpperCase()}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <div className="rating-wrapp">
                          {emp.show5star === true &&
                          emp.average_review !== null ? (
                            <div className="emp-rating-wrapp">
                              <Typography variant="h5" align="center">
                                {parseFloat(emp.average_review || 0).toFixed(1)}
                              </Typography>
                              <StarRatings
                                rating={Number(emp.average_review || 0)}
                                starRatedColor="#F46451"
                                starEmptyColor="#DFE7EC"
                                numberOfStars={1}
                                name="rating"
                                starDimension="24px"
                                starSpacing="0"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div className="left-job">
                          <Typography
                            style={{ display: "block" }}
                            className="job"
                            component="div"
                            noWrap
                            variant="inherit"
                          >
                            {emp.job_title}
                          </Typography>

                          <ChevronRight />
                        </div>
                      </Grid>
                    </Grid>
                    {/* <div className="employee-list-item">
                      <span>{emp.username.toUpperCase()}</span>
                      <div className="rating-wrapp">
                        {emp.show5star === true &&
                        emp.average_review !== null ? (
                          <StarRatings
                            rating={+emp.average_review || 0}
                            starRatedColor="#F46451"
                            starEmptyColor="#DFE7EC"
                            numberOfStars={5}
                            name="rating"
                            starDimension="25px"
                            starSpacing="0"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="left-job">
                        <div className="job">{emp.job_title}</div>

                        <ChevronRight />
                      </div>
                    </div> */}
                  </MenuItem>
                ))}
              </ul>
            ) : props.loading ? (
              <Loader />
            ) : (
              <div className="err p-3">
                There is no availability on selected services. Please try other
                ones instead
              </div>
            )}
          </RadioGroup>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={5} className="mrgn-top-30 hide-in-mob">
        <Cart
          center={props.center}
          cart={props.cart}
          editable={true}
          remove={removeItem}
        ></Cart>
      </Grid>
    </Grid>
  );
});

export default BookingStep2;
