import React from "react";
import StarRatings from "react-star-ratings";
import { Grid, Card, CardContent, Button } from "@material-ui/core";
import { imageUrl } from "../api/helpers";

const OtherLocation = React.memo((props) => {
  return (
    <section className="home-location">
      <div className="container">
        <h3>
          Please select one of those locations below for your appointments
        </h3>
        <Grid container spacing={8}>
          {props.locations.map((item) => (
            <Grid item xs={12} sm={12} md={6} key={item.store_key}>
              <a
                onClick={() => props?.onClick(item)}
                className="card salon-cards"
              >
                {item.slider_images_list?.length > 0 && (
                  <div className="imgbox">
                    <img
                      src={
                        item.slider_images_list
                          ? imageUrl(item.slider_images_list[0])
                          : ""
                      }
                      alt={item.center_name}
                    />
                  </div>
                )}

                <div className="data">
                  <h1>{item.center_name}</h1>
                  <h5>
                    <div className="icon">
                      <svg
                        width="18"
                        height="24"
                        viewBox="0 0 18 24"
                        fill="#14B9DB"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9 0C4.20726 0 0.308105 3.89916 0.308105 8.69184C0.308105 14.6397 8.0865 23.3715 8.41767 23.7404C8.72873 24.0868 9.27182 24.0862 9.58232 23.7404C9.9135 23.3715 17.6919 14.6397 17.6919 8.69184C17.6918 3.89916 13.7927 0 9 0ZM9 13.065C6.58865 13.065 4.62693 11.1032 4.62693 8.69184C4.62693 6.2805 6.5887 4.31878 9 4.31878C11.4113 4.31878 13.373 6.28055 13.373 8.69189C13.373 11.1032 11.4113 13.065 9 13.065Z" />
                      </svg>
                    </div>
                    <span>{item.address}</span>
                  </h5>
                  <div className="rating-wrapp">
                    <span>{item.rating}</span>
                    <StarRatings
                      rating={Number(item.rating_count) || 0}
                      starRatedColor="#F46451"
                      starEmptyColor="#DFE7EC"
                      numberOfStars={5}
                      name="rating"
                      starDimension="21px"
                      starSpacing="0"
                    />
                    <span>({item.rating_count} Reviews)</span>
                  </div>
                  {item.tel && (
                    <a
                      href={`tel:${item.tel}`}
                      onClick={(e) => e.preventDefault()}
                      className="owner-call-view"
                    >
                      <div className="icon">
                        <svg
                          className="contras-icon"
                          height="20px"
                          viewBox="0 0 20 20"
                          width="20px"
                          fill="#000000"
                        >
                          <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
                        </svg>
                      </div>
                      <span>{item.tel}</span>
                    </a>
                  )}

                  {!!item.center_name && (
                    <Button
                      className="btn-blue"
                      variant="contained"
                      //onClick={() => item.bookNow()}
                    >
                      {" "}
                      BOOK NOW{" "}
                    </Button>
                  )}
                </div>
              </a>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
});

export default OtherLocation;
