import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import Cart from "./Cart";
import api from "../api";
import BookingConfirmModal from "./BookingConfirmModal";
import ConfirmModal from "./ConfirmModal";
import UpdateNoteModal from "./UpdateNoteModal";
import { useConfirm } from "./context";
import { chain, countBy } from "lodash";
import { EditOutlined as EditOutlinedIcon } from "@material-ui/icons";
import ChangeCustomerNameModal from "./ChangeCustomerNameModal";
import ResidualDialog from "./ResidualDialog";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const getUpcomingBooking = (cid, center_id) => {
  const d = new Date();
  const utc_offset = d.getTimezoneOffset() * -1;
  return api.get("/customer/:cid/center/:center_id/booking/upcoming", {
    params: { cid, center_id, utc_offset },
  });
};
const getBooking = (id) => {
  return api.get("/booking/:id", { params: { id } });
};

const BookingStep5 = React.memo(
  ({
    center,
    cart,
    booking,
    newBooking,
    isNewBooking,
    handleEdit,
    handleGroupBooking,
    isGroupBookingCompleted,
    handleCancel,
    handleChangeBooking,
    isManageBooking,
    isShowUpcoming,
  }) => {
    const confirm = useConfirm();
    const [upcomingBooking, setUpcomingBooking] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [isNew, setIsNew] = useState(isNewBooking);
    const [isEditNote, setIsEditNote] = useState(false);
    const [selectedBookingData, setSelectedBookingData] = useState(null);
    const [isEditName, setIsEditName] = useState(false);
    const [isResidual, setIsResidual] = useState(false);
    const [groupCount, setGroupCount] = useState(0);
    const [isOpenGroupCompleted, setIsOpenGroupCompleted] = useState(
      isGroupBookingCompleted
    );

    const isGroupBooking = useMemo(() => {
      if (selectedBookingData?.item) {
        const groupLength = chain(selectedBookingData.item)
          .groupBy("start_time")
          .pickBy((x) => x.length > 1)
          .keys()
          .value();
        return groupLength?.length !== 0;
      }
      return false;
    }, [selectedBookingData]);

    const toggleCancel = () => {
      setCanUpdate(false);
      setShowConfirmation(!showConfirmation);
    };

    const handleAddNote = useCallback(() => {
      setIsEditNote(true);
    }, []);

    const canUpdateBooking = (src) => {
      setCanUpdate(false);
      const d = new Date();
      const utc_offset = d.getTimezoneOffset() * -1;
      api
        .get("/booking/:id/edit/check", {
          params: { bid: booking.booking_id, utc_offset },
        })
        .then((res) => {
          setCanUpdate(false);
          if (src === "edit") {
            //handleEdit();
            isGroupBooking ? setIsResidual(true) : handleEdit();
          } else if (src === "cancel") {
            handleCancelBookingConformation();
            //toggleCancel();
          }
        })
        .catch(() => {
          setCanUpdate(true);
        });
    };

    const groupBooking = (e) => {
      e.preventDefault();
      setIsNew(false);
      handleGroupBooking();
    };

    const getSelectedBooking = useCallback(
      (bookingId) => {
        if (
          !selectedBookingData ||
          selectedBookingData?.booking_id !== bookingId
        ) {
          getBooking(bookingId)
            .then((res) => {
              const item = res.data;
              const dt = new Date(item.time);
              let hours = dt.getHours();
              let minutes = dt.getMinutes();
              const ampm = hours >= 12 ? "pm" : "am";
              hours = hours % 12;
              hours = hours ? hours : 12;
              minutes = minutes < 10 ? "0" + minutes : minutes;
              const strTime = `${hours}:${minutes} ${ampm}`;
              item.time = `${dt.getDate()} ${
                months[dt.getMonth()]
              } ${dt.getFullYear()}, ${strTime}`;

              const bookingDetails = item?.booking_details?.map((i) => ({
                id: i.service_id,
                name: i.name,
                is_staff_requested: i?.is_staff_requested,
                duration: i.duration,
                price: i.price,
                isDisable: true,
                start_time: i.start_hour + ":" + i.start_minute,
              }));

              const groupByCount = countBy(bookingDetails, "start_time");
              setGroupCount(groupByCount[bookingDetails[0]?.start_time] || 0);

              const detail = item.booking_details[0];
              const data = {
                booking_id: bookingId,
                item: bookingDetails,
                note: item.note,
                status: item.status,
                deposit_amount: item.deposit_amount,
                employee: {
                  id: detail?.employee_id || 0,
                  username: detail?.is_staff_requested
                    ? detail?.username
                    : "Anyone",
                  job_title: detail?.job_title,
                },
                customer_id: item.customer_id,
                date: new Date(item?.time),
              };
              setSelectedBookingData(data);

              handleChangeBooking(data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      [selectedBookingData]
    );

    const handleCancelBookingConformation = useCallback(() => {
      setCanUpdate(false);
      confirm({
        description: (
          <h6 className="m-0">Are you sure that you want to cancel?</h6>
        ),
      })
        .then(() => {
          handleCancel();
        })
        .catch(() => {});
    }, [confirm, handleCancel]);

    const handleEditName = useCallback(() => {
      setIsEditName(true);
    }, []);

    useEffect(() => {
      if (cart.customer_id) {
        getUpcomingBooking(cart.customer_id, center.id)
          .then((res) => {
            const { data = [] } = res;
            const upcomingBookingNew = [];
            data.forEach((i) => {
              const dt = new Date(i.time);
              let hours = dt.getHours();
              let minutes = dt.getMinutes();
              const ampm = hours >= 12 ? "pm" : "am";
              hours = hours % 12;
              hours = hours ? hours : 12;
              minutes = minutes < 10 ? "0" + minutes : minutes;
              const strTime = `${hours}:${minutes} ${ampm}`;
              upcomingBookingNew.push({
                ...i,
                time: `${dt.getDate()} ${
                  months[dt.getMonth()]
                } ${dt.getFullYear()}, ${strTime}`,
              });
            });
            setUpcomingBooking(upcomingBookingNew);
          })
          .catch(() => {
            setUpcomingBooking([]);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateNote = useCallback((note) => {
      setIsEditNote(false);
      setSelectedBookingData((state) => {
        return {
          ...state,
          note,
        };
      });
    }, []);

    useEffect(() => {
      if (!booking.booking_id && upcomingBooking.length)
        changeBooking(upcomingBooking[0].id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upcomingBooking]);

    useEffect(() => {
      const init = async (bookingId) => {
        await getSelectedBooking(bookingId);
      };
      if (booking.booking_id) {
        init(booking.booking_id);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking?.booking_id]);

    const changeBooking = async (booking_id) => {
      setShowConfirmation(false);
      setCanUpdate(false);
      await getSelectedBooking(booking_id);
      // const selectedBooking =
      //   upcomingBooking.filter((i) => i.id === booking_id)[0] || {};
      // if (!selectedBooking.id) return;

      // const item = selectedBooking?.booking_details?.map((i) => ({
      //   id: i.service_id,
      //   name: i.name,
      //   duration: i.duration,
      //   price: i.price,
      // }));

      // const detail = selectedBooking.booking_details[0];
      // handleChangeBooking({
      //   booking_id,
      //   item,
      //   employee: {
      //     id: detail?.employee_id,
      //     username: detail?.is_staff_requested ?detail?.username : 'Anyone',
      //     job_title: detail?.job_title,
      //   },
      //   customer_id: selectedBooking.customer_id,
      //   date: new Date(selectedBooking.time),
      // });
    };

    return (
      <>
        <Grid
          container
          spacing={8}
          className="popup-container upcoming-popup-container"
        >
          <Grid item xs={12} sm={12} md={7}>
            <div className="list-of-radio-wrapp mrgn-top-30">
              <div className="appointment-details">
                <h3>
                  Welcome <span className="name">{cart.customer_name}!</span>{" "}
                  <IconButton size="small" onClick={handleEditName}>
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                </h3>
                {isManageBooking && <h5>Your upcoming Appointment</h5>}
                <ul className="time-list">
                  {upcomingBooking.map((i) => {
                    const loopDate = new Date(i.time);
                    const isActive =
                      cart.date &&
                      loopDate.getMonth() === cart.date.getMonth() &&
                      loopDate.getDate() === cart.date.getDate() &&
                      loopDate.getHours() === cart.date.getHours() &&
                      loopDate.getMinutes() === cart.date.getMinutes();
                    return (
                      <li key={i.id} className="card m-2 p-2">
                        <a
                          href="/#"
                          className={isActive ? "active" : ""}
                          onClick={(e) => {
                            e.preventDefault();
                            changeBooking(i.id);
                          }}
                        >
                          <span> {i.time}</span>{" "}
                          {i.status === "Pending" && (
                            <span className="pending-label">Pending</span>
                          )}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                {/* <Button variant="contained" className="btn-blue hide-in-mob" onClick={e => { e.preventDefault(); newBooking() }}>
								BOOK NEW APPOINTMENT 
							</Button> */}

                <div className="hide-in-md">
                  <Button
                    variant="contained"
                    className="btn-blue "
                    onClick={groupBooking}
                  >
                    ADD ANOTHER PERSON
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <div className="popup-sidebar-wrapp mrgn-top-30">
              <Cart center={center} cart={cart}></Cart>
              <Button
                className="update-not-btn"
                size="small"
                color="primary"
                onClick={handleAddNote}
              >
                + Update Booking Notes
              </Button>
              {!showConfirmation && (
                <>
                  <div className="btn-box hide-in-md">
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        canUpdateBooking("edit");
                      }}
                    >
                      {" "}
                      RESCHEDULE{" "}
                    </Button>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      style={{
                        marginLeft: 12,
                      }}
                      onClick={(e) => {
                        e.preventDefault();

                        canUpdateBooking("cancel");
                      }}
                    >
                      {" "}
                      CANCEL{" "}
                    </Button>
                  </div>
                </>
              )}
              <div className="display-in-md add-anther-person-mob fixed-in-mob">
                {!showConfirmation && (
                  <>
                    <div className="btn-box">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          canUpdateBooking("edit");
                        }}
                      >
                        {" "}
                        RESCHEDULE{" "}
                      </Button>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        style={{
                          marginLeft: 12,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          canUpdateBooking("cancel");
                        }}
                      >
                        {" "}
                        CANCEL{" "}
                      </Button>
                    </div>
                  </>
                )}
                <Button
                  variant="contained"
                  className="btn-blue"
                  onClick={groupBooking}
                >
                  ADD ANOTHER PERSON
                </Button>
              </div>

              {canUpdate && (
                <small className="err">{`You can not cancel/edit the Appointment within ${center.advance_hour_cancel_reschedule_online}-hour of appointment time. Please contact us at ${center.tel} for assistance`}</small>
              )}
            </div>
          </Grid>
        </Grid>

        {((isNew && booking?.booking_id) || isOpenGroupCompleted) && (
          <BookingConfirmModal
            onAddOtherPerson={(e) => groupBooking(e)}
            onClose={() =>
              isOpenGroupCompleted
                ? setIsOpenGroupCompleted(false)
                : setIsNew(false)
            }
            date={cart.date}
            groupBookingCount={isOpenGroupCompleted ? groupCount : 0}
            center={center}
          />
        )}

        {isEditNote && selectedBookingData?.booking_id && (
          <UpdateNoteModal
            booking={selectedBookingData}
            onClose={() => setIsEditNote(false)}
            onUpdate={handleUpdateNote}
          />
        )}

        {isEditName && (
          <ChangeCustomerNameModal
            cart={cart}
            onClose={() => setIsEditName(false)}
            onUpdate={handleUpdateNote}
          />
        )}
        {isResidual && (
          <ResidualDialog
            center={center}
            onClose={() => setIsResidual(false)}
          />
        )}
      </>
    );
  }
);

export default BookingStep5;
