import React from "react";
import DefaultDialog from "./context/Dialog";

const ResidualDialog = ({ center, onClose }) => {
  return (
    <DefaultDialog maxWidth="sm" title={"Reschedule"} onClose={onClose}>
      <div>
        <p className="errTimeMsg m-0">
          Please call <a href="tel: {center.tel}">{center.tel}</a> for
          assistance if you need to reschedule a group booking
        </p>
      </div>
    </DefaultDialog>
  );
};

export default ResidualDialog;
