import React from "react";
import { Button } from "@material-ui/core";

const Footer = React.memo((props) => {
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="copyright">
            Copyright &copy; 2020 Belle Biz Marketing. All Rights Reserved
          </div>
          <div className="social-links my-3 text-center">
            <a href="/#" className="facebook">
              <i className="icon-facebook"></i>
            </a>
          </div>
        </div>
      </footer>
      {!props.isOwner && (
        <div className="btn-mob-stick">
          <Button
            className="btn-blue"
            variant="contained"
            onClick={() => props.bookNow()}
          >
            {" "}
            BOOK NOW{" "}
          </Button>
        </div>
      )}
    </>
  );
});

export default Footer;
