import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import store from "./store";
import SpaApp from "./containers/SpaApp.js";
import Owner from "./containers/Owner.js";
import Center from "./containers/Center.js";
import NotFound from "./components/NotFound";
import DialogsProvider from "./components/context/Provider";
import SocketIOProvider from "./components/context/SocketIOContext";

const App = () => (
  <Provider store={store}>
    <Router>
      <SpaApp dispatch={store.dispatch} getState={store.getState}>
        <SocketIOProvider>
          <DialogsProvider>
            <Switch>
              <Route path="/owner/:key" exact component={Owner} />
              <Route path="/center/:key" exact component={Center} />
              <Route component={NotFound} />
            </Switch>
          </DialogsProvider>
        </SocketIOProvider>
      </SpaApp>
    </Router>
  </Provider>
);

export default App;
