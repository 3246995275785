import api from "../api";

const bookingRequest = () => ({
  type: "BOOKING_REQUEST",
});

const bookingSuccess = (booking) => ({
  type: "BOOKING_SUCCESS",
  payload: booking,
});

const bookingError = (error) => ({
  type: "BOOKING_ERROR",
  payload: { error },
});

const bookingFail = () => ({
  type: "BOOKING_FAIL",
});

const createBooking = (data) => {
  return (dispatch) => {
    dispatch(bookingRequest());
    return api
      .post("/booking", data)
      .then((res) => {
        dispatch(
          bookingSuccess({
            booking_id: res.data.booking_id,
            customer_id: data.customer_id,
            customer_name: data.customer_name,
          })
        );
        return res;
      })
      .catch((err) => {
        const error =
          err?.response?.data?.response?.data?.message || "Staff not available";
        dispatch(bookingError(error));
        return null;
        //dispatch(bookingFail());
      });
  };
};

const reInitBooking = () => ({
  type: "RE_INIT_BOOKING",
});

const closeBooking = () => ({
  type: "CLOSE_BOOKING",
});

const cancelBookingRequest = () => ({
  type: "CANCEL_BOOKING_REQUEST",
});

const cancelBookingSuccess = () => ({
  type: "CANCEL_BOOKING_SUCCESS",
});

const cancelBookingFail = (payload) => ({
  type: "CANCEL_BOOKING_FAIL",
});

const cancelBooking = (data) => {
  return (dispatch) => {
    dispatch(cancelBookingRequest());
    return api
      .post("/booking/:id/cancel", data)
      .then((res) => {
        dispatch(cancelBookingSuccess());
        return res;
      })
      .catch((err) => {
        dispatch(cancelBookingFail());
        return null;
      });
  };
};

const updateBookingRequest = () => ({
  type: "UPDATE_BOOKING_REQUEST",
});

const updateBookingSuccess = () => ({
  type: "UPDATE_BOOKING_SUCCESS",
});

// const updateBookingFail = payload => ({
//     type: 'UPDATE_BOOKING_FAIL'
// });

const updateBooking = (payload) => {
  return (dispatch) => {
    const { data, booking_id } = payload;
    dispatch(updateBookingRequest());
    return api
      .post("/booking/:id", data, { params: { booking_id } })
      .then((resp) => {
        dispatch(updateBookingSuccess());
        return resp;
      })
      .catch((err) => {
        const error =
          err?.response?.data?.response?.data?.message || "Staff Not found";
        dispatch(bookingError(error));
        return null;
      });
  };
};
const updateBookingNote = (payload) => {
  return (dispatch) => {
    const { data, booking_id } = payload;
    dispatch(updateBookingRequest());
    return api
      .post("/booking/note/:id", data, { params: { booking_id } })
      .then((resp) => {
        dispatch(updateBookingSuccess());
        return resp;
      })
      .catch((err) => {
        const error =
          err?.response?.data?.response?.data?.message ||
          "Error while on update not";
        dispatch(bookingError(error));
        return null;
      });
  };
};
const groupBooking = (payload) => {
  return (dispatch) => {
    const { data, booking_id } = payload;
    dispatch(updateBookingRequest());
    return api
      .post("/booking/group/:id", data, { params: { booking_id } })
      .then((resp) => {
        dispatch(updateBookingSuccess());
        return resp;
      })
      .catch((err) => {
        const error =
          err?.response?.data?.response?.data?.message || "Staff Not found";
        dispatch(bookingError(error));
        return null;
      });
  };
};

export {
  createBooking,
  reInitBooking,
  closeBooking,
  cancelBooking,
  updateBooking,
  groupBooking,
  updateBookingNote,
  bookingError,
};
