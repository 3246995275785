const duration = (minute) => {
  if (!minute > 0) return "";

  const h = Math.floor(minute / 60);
  const m = Math.round(minute % 60);
  let durationString = "";
  if (h > 0) {
    durationString += `${h} hr `;
  }
  if (m > 0) {
    durationString += `${m} min `;
  }
  return durationString;
};

export default duration;
