import OwnerController from './controllers/OwnerController';
import CenterController from './controllers/CenterController';
import ServiceController from './controllers/ServiceController';
import EmployeeController from './controllers/EmployeeController';
import CustomerController from './controllers/CustomerController';
import BookingController from './controllers/BookingController';
import StripeController from './controllers/StripeController';

const routes = {
  get: {},
  post: {},
  put: {}
};

// GET
routes.get['/owner/:okey/center'] = OwnerController.getOwnerCenters;
routes.get['/owner/center/:ckey'] = OwnerController.getOtherCenters;
routes.get['/center/:ckey'] = CenterController.getCenterByKey;
routes.get['/center/:cid/business-hour'] = CenterController.getBusinessHour;
routes.get["/center/:id/payment-gateway"] = CenterController.getPaymentGetaway;

routes.get['/center/:cid/category/service'] = ServiceController.getServices;
routes.get["/service/add-on/:sid"] = ServiceController.getAddOnServiceServices;
routes.get["/service/add-on-check/:sid"] = ServiceController.checkAddOnService;


routes.get['/center/:cid/employee'] = EmployeeController.getEmployees;
routes.get['/employee/:eid/availability'] = EmployeeController.getEmployeeAvailability
routes.get['/customer/mobile/:mobile'] = CustomerController.getCustomer
routes.get['/customer/deposit-count/:id'] = CustomerController.getDepositCount;
routes.get['/customer/:cid/center/:center_id/booking/upcoming'] = CustomerController.getUpcomingBooking
routes.get['/booking/:id/edit/check'] = BookingController.checkForEdit
routes.get['/booking/:id'] = BookingController.getBooking

// POST
routes.post['/customer/send-activation'] = CustomerController.sendActivationCode
routes.post['/customer/verify-otp'] = CustomerController.verifyOtp
routes.post['/customer'] = CustomerController.addCustomer
routes.post['/customer/:id'] = CustomerController.changeNameCustomer
routes.post['/booking'] = BookingController.createBooking
routes.post['/booking/:id/cancel'] = BookingController.cancelBooking
routes.post['/booking/:id'] = BookingController.updateBooking
routes.post["/booking/group/:id"] = BookingController.groupBooking
routes.post['/payment/stripe'] = StripeController.createPaymentIntent
routes.post["/booking/note/:id"] = BookingController.updateBookingNote;

export default routes;
