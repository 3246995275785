import requestService from '../services/requestService';

const createBooking = (data, query) => {
  const request = {
    method: 'post',
    url: `/booking`,
    params: {
      type: 'create_booking',
    },
    data
  };

  return requestService(request);
};

const cancelBooking = (data, query) => {
  const request = {
    method: 'post',
    url: `/booking/${data.booking_id}/cancel`,
    params: {
      type: 'cancel_booking',
    },
    data
  };

  return requestService(request);
};

const updateBooking = (data, query) => {
  console.log({data, query});
  const request = {
    method: 'post',
    url: `/booking/${query.booking_id}`,
    params: {
      type: 'update_booking',
    },
    data
  };

  return requestService(request);
};

const updateBookingNote = (data, query) => {
  console.log({data, query});
  const request = {
    method: 'post',
    url: `/booking/note/${query.booking_id}`,
    params: {
      type: 'update_booking_note',
    },
    data
  };

  return requestService(request);
};
const groupBooking = (data, query) => {
  const request = {
    method: 'post',
    url: `/booking/group/${query.booking_id}`,
    params: {
      type: 'group_booking',
    },
    data
  };

  return requestService(request);
};
const getBooking = query => {
  const request = {
    method: "get",
    url: `/booking/${query?.id}`,
    params: {
      type: "get_booking",
    },
  };

  return requestService(request);
};

const checkForEdit = query => {
  const request = {
    method: 'get',
    url: `/booking/${query.bid}/edit/check`,
    params: {
      type: 'check_for_edit',
      utc_offset: query.utc_offset
    }
  };

  return requestService(request);
};

export default {
  createBooking,
  cancelBooking,
  updateBooking,
  checkForEdit,
  groupBooking,
  getBooking,
  updateBookingNote,
};
