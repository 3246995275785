
export function isBase64(key) {
  try {
    window.atob(key.trim());
    return true
  } catch (error) {
    return false
  }

}

export function encrypt(text) {
  if (!text) {
    return ''
  }
  text = text.trim()
  if (!isBase64(text)) {
    const encrypted = window.btoa(text);
    return encrypted
  }
  else{
    return text
  }

}

export function decrypt(encryptText) {
  try {
    if (!encryptText) {
      return '';
    }
    const decrypted = window.atob(encryptText);
    return decrypted;

  } catch (error) {
    console.log('Decrypt error', error);
    return encryptText;
  }
}



