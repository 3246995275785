import {
    Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { memo, useMemo } from "react";
import useWindowDimensions from "./hooks/windowDimensions";



const BookingPopup = memo(({ image, onClose }) => {
    const { height, width } = useWindowDimensions();
    const isMobile = useMemo(() => width <= 640, [width]);
  return (
    <Dialog
      fullWidth
      // fullScreen={isMobile}
      size={"sm"}
      open={true}
      onClose={onClose}
    >
      <DialogContent className="popup-content">
        <Box className="content">
          <IconButton
            className="popup-close-btn"
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            {" "}
            <CloseIcon fontSize={isMobile ? "medium" : "large"} />{" "}
          </IconButton>
          <img className="popup-image" src={image} alt="" />
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default BookingPopup;
