import React, { useState, useEffect, createRef, useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import Slider from "react-slick";

import Cart from "./Cart";
import Loader from "./Loader";
import duration from "../helpers/duration";
import Api from "../api";
import SelectAddOnServiceModal from "./SelectAddOnServiceModal";
import ConfirmModal from "./ConfirmModal";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    width: 24,
    height: 24,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "var(--primary-color)",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "var(--primary-color)",
    },
  },
  root: {
    width: "100%",

    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
      "&:before": {
        opacity: 1,
      },
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0 0 0",
      "& .makeStyles-heading-5": {
        paddingBottom: 0,
      },
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 500,
    padding: "8px 0",
    color: "#000",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 4,
  autoplay: false,
  className: "category-slider",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        // variableWidth: true,
        focusOnSelect: true,
        arrows: false,
        // infinite: false
      },
    },
  ],
};
const checkAddOnService = (sid, cid) => {
  return Api.get("/service/add-on-check/:sid", { params: { sid, cid } });
};

const BookingStep1 = React.memo((props) => {
  const classes = useStyles();
  const [isShowOpenAddOnService, setIsShowOpenAddOnService] = useState(null);
  const [initialServices, setInitialServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    services: [],
  });
  const [allCategories, setAllCategories] = useState([
    {
      id: 0,
      name: "All Services",
      services: [],
    },
  ]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isServiceLimitRich, setIsServiceLimitRich] = useState(false);
  const searchRef = createRef();

  const [expanded, setExpanded] = React.useState(false);

  const handleTogglePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseAddServiceDialog = useCallback(() => {
    setIsShowOpenAddOnService(null);
  }, []);

  useEffect(() => {
    if (props.services.length) {
      setInitialServices(props.services);
      let allServices = [];
      const allCategoriesNew = allCategories;
      props.services.forEach((i) => {
        allCategoriesNew.push(i);
        allServices = [...allServices, ...i.services];
      });
      allCategoriesNew[0].services = allServices;
      setSelectedCategory({ ...allCategoriesNew[0] });
      setAllCategories(allCategoriesNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.services]);

  useEffect(() => {
    setSelectedServices(
      props.cart.item
        .map((i) => {
          if (!i.isDisable) {
            return i.id;
          }
        })
        .filter(Boolean)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cart.item.length]);

  const onCatClick = (e, id) => {
    e.preventDefault();
    const val = searchRef.current.value;
    const category = allCategories.filter((i) => i.id === id)[0] || {
      ...allCategories[0],
    };
    setSelectedCategory({
      ...category,
      services: val
        ? category.services.filter((i) => i.name.toLowerCase().includes(val))
        : category.services,
    });
  };

  const handleChange = (e, service) => {
    const id = service?.id;
    // const item = props?.cart?.item.filter((i)=> i.id === id)[0]
    // if (item && item?.isDisable){
    //   return
    // }
    console.log(selectedServices?.length >= 6, e.target.checked);
    if (selectedServices?.length >= 6 && e.target.checked) {
      setIsServiceLimitRich(true);
      return;
    }
    props.handleCart(e.target.checked ? "add" : "remove", id);
    if (e.target.checked) {
      checkAddOnService(id, props.center?.id).then(({ data }) => {
        if (data === true) {
          setIsShowOpenAddOnService(id);
        } else {
          setIsShowOpenAddOnService(null);
        }
      });
    }
  };

  const onSearch = () => {
    const val = searchRef.current.value;
    console.log(props?.services, "props?.services");

    const categories = JSON.parse(JSON.stringify(props?.services));

    const searchedService = categories
      ?.map((category) => {
        const catServices = category?.services?.filter(
          (item) => item?.name?.toLowerCase()?.indexOf(val) > -1
        );
        if (catServices?.length > 0) {
          category.services = catServices;
          return category;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    if (searchedService.length === 1) {
      setExpanded(searchedService[0]?.id);
    }
    setInitialServices(searchedService);
    // const { services: allServices = [] } =
    //   allCategories.filter((i) => i.id === selectedCategory.id)[0] || {};
    // setSelectedCategory({
    //   id: selectedCategory.id,
    //   name: selectedCategory.name,
    //   services: val
    //     ? allServices.filter((i) => i.name.toLowerCase().includes(val))
    //     : allServices,
    // });
  };

  const removeItem = (id) => {
    props.handleCart("remove", id);
  };

  return (
    <div>
      {/*      <Slider {...settings}>
        {allCategories.map((i) => (
          <a
            key={i.id}
            href="/#"
            className={
              i.id === selectedCategory.id
                ? "active category-name"
                : "category-name"
            }
            onClick={(e) => onCatClick(e, i.id)}
          >
            {i.name}
          </a>
        ))}
      </Slider> */}
      <Grid container spacing={8} className="popup-container">
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <div className="search-box">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.0703 25.4898L20.5516 18.9711C22.143 17.0297 23.0945 14.5523 23.0945 11.8453C23.0945 5.63828 18.0578 0.601562 11.8508 0.601562C5.63828 0.601562 0.601562 5.63828 0.601562 11.8508C0.601562 18.0633 5.63828 23.1 11.8508 23.1C14.5523 23.1 17.0352 22.1484 18.9766 20.557L25.4898 27.0703C25.9273 27.5078 26.6328 27.5078 27.0703 27.0703C27.5078 26.6328 27.5078 25.9273 27.0703 25.4898ZM2.83281 11.8508C2.83281 6.87422 6.86875 2.83828 11.8453 2.83828C16.8219 2.83828 20.8578 6.87422 20.8578 11.8508C20.8578 16.8273 16.8219 20.8633 11.8453 20.8633C6.86875 20.8633 2.83281 16.8273 2.83281 11.8508Z"
                fill="#082B44"
                fillOpacity="0.3"
              />
            </svg>
            <TextField
              id="outlined-basic"
              placeholder="Search by a service name"
              variant="outlined"
              onChange={onSearch}
              inputRef={searchRef}
            />
          </div>
          <div className="list-of-radio-wrapp card">
            <div className={classes?.root}>
              {initialServices?.map((category) => (
                <Accordion
                  key={category?.id}
                  expanded={expanded === category?.id}
                  onChange={handleTogglePanel(category?.id)}
                >
                  <AccordionSummary
                    className={classes.summary}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className={classes.heading}>
                      {category?.name.toUpperCase()}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ width: "100%" }}>
                      {category?.description ? (
                        <Typography color="textSecondary" variant="body1">
                          {category?.description}
                        </Typography>
                      ) : null}
                      <RadioGroup aria-label="gender" name="customized-radios">
                        <ul className="list-of-radio">
                          {props.loading ? (
                            <Loader />
                          ) : (
                            <>
                              {category?.services?.map((service) => (
                                <li key={service.id}>
                                  <FormControlLabel
                                    value={service.id}
                                    control={
                                      <Checkbox
                                        name={service.name}
                                        disabled={!!service?.isDisable}
                                        onChange={(e) =>
                                          handleChange(e, service)
                                        }
                                        checked={selectedServices.includes(
                                          service.id
                                        )}
                                        color="primary"
                                        icon={<span className={classes.icon} />}
                                        checkedIcon={
                                          <span
                                            className={clsx(
                                              classes.icon,
                                              classes.checkedIcon
                                            )}
                                          />
                                        }
                                      />
                                    }
                                    label={service.name}
                                  />
                                  {!!props.center.is_service_price && (
                                    <div className="amt p-2">
                                      $ {service.price}
                                    </div>
                                  )}
                                  {!!props.center.is_duration && (
                                    <span className="time">
                                      {duration(service.duration)}
                                    </span>
                                  )}
                                  {service.description && (
                                    <p>{service.description}</p>
                                  )}
                                </li>
                              ))}
                            </>
                          )}
                        </ul>
                      </RadioGroup>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>

            {/* <h3 className="p-2" title={selectedCategory.name}>
              {selectedCategory.name}
            </h3>
            <RadioGroup aria-label="gender" name="customized-radios">
              <ul className="list-of-radio">
                {props.loading ? (
                  <Loader />
                ) : (
                  <>
                    {selectedCategory.services.map((service) => (
                      <li key={service.id}>
                        <FormControlLabel
                          value={service.id}
                          control={
                            <Checkbox
                              name={service.name}
                              disabled={!!service?.isDisable}
                              onChange={(e) => handleChange(e, service)}
                              checked={selectedServices.includes(service.id)}
                              color="primary"
                              icon={<span className={classes.icon} />}
                              checkedIcon={
                                <span
                                  className={clsx(
                                    classes.icon,
                                    classes.checkedIcon
                                  )}
                                />
                              }
                            />
                          }
                          label={service.name}
                        />
                        {!!props.center.is_service_price && (
                          <div className="amt p-2">$ {service.price}</div>
                        )}
                        {!!props.center.is_duration && (
                          <span className="time">
                            {duration(service.duration)}
                          </span>
                        )}
                        {service.description && <p>{service.description}</p>}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </RadioGroup> */}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className="hide-in-mob">
          <Cart
            center={props.center}
            cart={props.cart}
            remove={removeItem}
            editable={true}
          ></Cart>
        </Grid>
      </Grid>

      {isServiceLimitRich && (
        <ConfirmModal
          description={
            <p style={{ margin: 0, fontSize: "15px" }}>
              You only can book 6 services maximum on website. Please call{" "}
              <a
                style={{ color: "var(--primary-color)" }}
                href="tel: {props.center.tel}"
              >
                {props.center.tel}
              </a>{" "}
              if you want to book more.
            </p>
          }
          onClose={() => {
            setIsServiceLimitRich(false);
          }}
        />
      )}

      {isShowOpenAddOnService && (
        <SelectAddOnServiceModal
          center={props.center}
          serviceId={isShowOpenAddOnService}
          cart={props.cart}
          onRichMaxLimit={() => setIsServiceLimitRich(true)}
          handleCart={props.handleCart}
          onClose={handleCloseAddServiceDialog}
        />
      )}
    </div>
  );
});

export default BookingStep1;
