import requestService from "../services/requestService";

const getServices = (query) => {
  const request = {
    method: "get",
    url: `/center/${query.cid}/category/service`,
    params: {
      type: "get_services",
    },
  };

  return requestService(request);
};

const checkAddOnService = (query) => {
  const request = {
    method: "get",
    url: `/service/add-on-check/${query.sid}`,
    params: {
      type: "get_add_on_check_services",
      cid: query?.cid,
    },
  };

  return requestService(request);
};
const getAddOnServiceServices = (query) => {
  const request = {
    method: "get",
    url: `/service/add-on/${query.sid}`,
    params: {
      type: "get_add_on_services",
      cid: query?.cid,
    },
  };

  return requestService(request);
};

export default {
  getServices,
  getAddOnServiceServices,
  checkAddOnService,
};
